import React from 'react';
import Status from './Status';
import ByteConverter from '@wtfcode/byte-converter'
import dayjs from 'dayjs';
import { Button, List, Avatar, Row, Col, Upload, message, Modal, Input, Form, Checkbox, Layout } from 'antd';
import { connect } from "react-redux";
import { MAINTENANCE } from '../constants/routes';
import { isMobileApp, saveFile, sendDataToMobileApp, unixToFormatted } from './Utilities';
import { NAME, NAME_MSG } from '../constants/regex';
import { top, left, select } from "../api/actions";

const byteConverter = new ByteConverter();

function mapDispatchToProps(dispatch) {
    return {
        left: currentLeft => dispatch(left(currentLeft)),
        top: currentTop => dispatch(top(currentTop)),
        select: record => dispatch(select(record))
    }
}

function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        unit: state.unit,
        access: state.access
    }
}

function extractCoordinate(value, isLongitude)
{
    var direction
        = isLongitude
        ? (value > 0) ? 'E' : 'W'
        : (value > 0) ? 'N' : 'S';

    value = Math.abs(value);
    var degree = Math.floor(value);

    value = 60 * (value - degree);
    var minute = Math.floor(value);

    value = 600 * (value - minute);
    var second = Math.round(value)/10;

    return `${degree}°${minute}'${second}"${direction}`;
}

class Maintenance extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isConfigModalLoaded: true,
            isConfigModalVisible: false,
            isStorageModalLoaded: true,
            isStorageModalVisible: false,
            data: [
                {
                    title: "Configuration",
                    avatar: "setting",
                    actions: [
                        {
                            name: "Import", 
                            icon: "upload",
                            accept: ".json",
                            beforeUpload: file => this.import(file)
                        },
                        {
                            name: "Export", 
                            icon: "download",
                            onClick: () => this.export()
                        }
                    ],
                    description: "1.0" // TODO
                },
                {
                    title: "Date & Time",
                    avatar: "clock-circle"
                },
                {
                    title: "Device ID",
                    avatar: "home",                        
                },
                {
                    title: "Firmware",
                    avatar: "tool",
                    actions: [
                        {
                            name: "Install from uploaded file", 
                            icon: "upload",
                            accept: ".npkg,text/parameters,application/octet-stream",
                        },
                        {
                            name: "Check update status",
                            icon: "question",
                            onClick: () => this.update()
                        },
                        {
                            name: "Install available update",
                            icon: "check",
                            onClick: () => this.update(true)
                        }
                    ],
                },
                {
                    title: "Ethernet Connection State",
                    avatar: "apartment",
                },
                {
                    title: "Ethernet Network Address",
                    avatar: "apartment",
                },
                {
                    title: "Media Backup",
                    avatar: "usb",
                    actions: [
                        {
                            name: "Prepare and clean external storage first"
                        },
                        {
                            name: "Start", 
                            icon: "export",
                            onClick: () => this.startMediaBackup()
                        }
                    ]
                },
                {
                    title: "Location (GPS)",
                    avatar: "environment",
                },
                {
                    title: "Hardware",
                    avatar: "home",  
                    actions: [
                        {
                            name: "Backup",
                            icon: "download",
                            onClick: () => this.backup() 
                        },
                        {
                            name: "Restore",
                            icon: "upload",
                            accept: ".npkg,text/parameters,application/octet-stream",
                        },
                        {
                            name: "Reboot",
                            icon: "reload",
                            onClick: () => this.reboot()
                        },
                        {
                            name: "Shutdown",
                            icon: "poweroff",
                            onClick: () => this.shutdown()
                        }
                    ]
                },
                {
                    title: "Hostname",
                    avatar: "home"                         
                },
				{
                    title: "Mobile Modem Firmware",
                    avatar: "global"
                },
                {
                    title: "Mobile Connection State",
                    avatar: "global"
                },
                {
                    title: "Mobile Network Address",
                    avatar: "global"
                },
                {
                    title: "Mobile Signal Strength",
                    avatar: "global"
                },
                {
                    title: "NVR Name",
                    avatar: "home"                         
                },	
                {
                    title: "Storage State",
                    avatar: "hdd",
                    actions: [
                        {
                            name: "Format", 
                            icon: "delete",
                            onClick: () => this.format()
                        }
                    ]
                },
                {
                    title: "Storage Used/Total",
                    avatar: "hdd"
                },
                {
                    title: "Virtual Connection State",
                    avatar: "lock",
                },
                {
                    title: "Virtual Network Address",
                    avatar: "lock",
                },
                {
                    title: "Wireless Connection State",
                    avatar: "wifi",
                },
                {
                    title: "Wireless Network Address",
                    avatar: "wifi",
                }
            ],
            prepareExternalStorage: false,
            disableInstallUpdate: true,
            filenameToUpdate: "",
            updateStatus: ""
        };

        this.show = this.show.bind(this)
        this.debug = window.config.DEBUG
    }

    componentDidMount() {
        this.props.select(null)
        this.props.top("maintenance")
        this.props.left(null)
        this.setState({ isLoaded: false }, () => this.show());
        this.timerShow = !this.debug && setInterval(() => this.show(), 10000);
    }

    componentWillUnmount() {
        clearInterval(this.timerShow);
    }

    configChange = value => this.setState({ config: value.target.value });
    storageEncryptionPasswordChange = value => this.setState({ storageEncryptionPassword: value.target.value });

    import = file => {

        this.setState({ isLoaded: false, config: null }, () => {

            const reader = new FileReader();

            reader.onloadend = event => {
                if (reader.readyState === 2) {
                    message.success(`${file.name} uploaded successfully.`);
                    this.setState({ 
                        config: event.target.result ? event.target.result : null, 
                        isConfigModalVisible: true,
                    });
                } else {
                    message.error(`${file.name} upload failed.`);
                }
                this.setState({ isLoaded: true });
            };

            reader.readAsText(file);
        });

        return false; // prevent usual upload
    }

    confirmImport = () => {

        this.setState({ isConfigModalLoaded: false }, () => {
            this.props.processMessage([{
                request: "configuration",
                method: "set",
                params: [{
                    clearusers: false,
                    factoryreset: false,
                    users: [],
                    settings: btoa(this.state.config)
                }]
            }])
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => {
                this.setState({ config: null, modalError: null, isConfigModalVisible: false, isConfigModalLoaded: true });
            });
        });
    }
    
    export = () => {

        this.props.processMessage([{ 
            request: "configuration",
            method: "get",
            params: []
        }])
        .then(res => {
            const settings = res?.["configuration"]?.params?.[0]?.settings
            if (isMobileApp) {
                sendDataToMobileApp("data:application/json;base64," + settings)
            } else {
                saveFile(new Blob([Buffer.from(settings, 'base64')]), "config_" + dayjs().format("YYYYMMDD_HHmmss") + ".json") // decode from Base64
            }
        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }

    format = () => this.setState({ isLoaded: false, storageEncryptionPassword: null }, () => this.setState({ isStorageModalVisible: true, isLoaded: true }));
    
    confirmFormat = () => {
        this.setState({ isStorageModalLoaded: false }, () => {
            this.props.processMessage([{ 
                request: "storage",
                method: "set",
                params: [{ password: this.state.storageEncryptionPassword }]            
            }])
            .then(() => message.info("A reboot of the device is necessary for the changes to take effect!", 6))
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => {
                this.setState({ storageEncryptionPassword: null, modalError: null, isStorageModalVisible: false, isStorageModalLoaded: true });
            });
        });
    }

    cancelModal = () => this.setState({ config: null, storageEncryptionPassword: null, modalError: null, isConfigModalVisible: false, isStorageModalVisible: false });
    
    reboot = () => {
        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "restart",
                method: "set",
                params: []
            }])
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    shutdown = () => {
        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "shutdown",
                method: "set",
                params: []
            }])
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    backup = () => {
        const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER } } = window
        const { unit: { proxyKey} } = this.props

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "backup",
                method: "set",
                params: []
            }])
            .then(res => {
                const fileName = res?.["backup"]?.params?.[0]?.filename
                const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
                const server = BUILD ? location.hostname : SERVER;
                const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
                const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
                const backup = "/backup_images/" + fileName
                
                const backupConfig = protocol + server + port + path + backup;

                this.debug && console.log("backupConfig", backupConfig)

                if (isMobileApp) {
                    sendDataToMobileApp(backupConfig); 
                } else {
                    fetch(backupConfig)
                    .then(res => {
                        if (res.ok) {
                            return res.blob() 
                        } else {
                            var error = {};
                            error.msg = res.statusText;
                            error.type = "error";
                            console.error(res.status, res.statusText); 
                            this.setState({ error })
                        }
                    })  
                    .then(data => saveFile(data, fileName))
                    .catch(error => console.error(error))
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    startMediaBackup = () => {
        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "mediabackup",
                method: "set",
                params: [{
                    devices: [],
                    starttime: 0,
                    endtime: 2147483647,
                    protectedonly: false,
                    preparestorage: this.state.prepareExternalStorage
                }]
            }])
            .then(res => {
                const mediabackup = res?.["mediabackup"]?.params?.[0]
                let msg = "Media backup failed";
                let level = "error";

                if (mediabackup) {
                    if (mediabackup.progress > 0) {
                        msg = "Media backup started";
                        level = "success";
                    } else if (mediabackup.progress === 0) {
                        msg = "Media backup not started";
                        level = "warning";
                    } 
                    if (mediabackup.info) {
                        msg += ": " + mediabackup.info;
                    }
                } 
                  
                switch (level) {
                    case "success":
                        message.success(msg);
                        break;
                    case "info":
                        message.info(msg);
                        break;
                    case "error":
                        message.error(msg);
                        break;
                    default:
                        break;
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    onChangeCheckbox = (e) => {
        this.setState({ prepareExternalStorage: e.target.checked })
    }

    upload = (info, action) => {
        this.setState({ isLoaded: false }, () => {
            const name = info.file.name;
            const status = info.file.status;
            if (status === 'done') {
                message.success(`${name} uploaded successfully.`);

                if (action === "Install from uploaded file") {
                    this.setState({ 
                        disableInstallUpdate: false,
                        filenameToUpdate: `file:${name}` // set filename to install update from uploaded file
                    }, () => this.update(true)) // automatically install update after upload
                }

                if (action === "Restore") {
                    this.setState({ 
                        filenameToUpdate: `restore:${name}` // set filename to restore from uploaded file
                    }, () => this.update(true, true)) // automatically install restore after upload
                }

            } else if (status === 'error') {
                message.error(`${name} upload failed.`);
            }
            this.setState({ isLoaded: true })
        });
    }

    update = (install = false, restore = false) => {
        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "update",
                method: "set",
                params: [{ selected: install ? this.state.filenameToUpdate : "" }] // empty string to check for updates OR filename to install update
            }])
            .then(res => {
                const update = res?.["update"]?.params?.[0]
                if (update && !restore) {
                    if (update.info) {
                        this.setState({ updateStatus: update.info })
                    }
                    if (update.availableupdates?.length) {
                        this.setState({ 
                            disableInstallUpdate: false,
                            filenameToUpdate: update.availableupdates[0].ident // set filename to install update
                        })
                    }
                    if (update.locked) {
                        this.setState({ disableInstallUpdate: true }) // disable update button if update is locked
                    }
                } else {
                    message.info("A reboot of the device is necessary for the changes to take effect!", 6)
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    show() {

        this.props.processMessage([{ 
            request: "liveparams",
            method: "get",
            params: []
        }])
        .then(res => {
            var liveparamsRaw = res["liveparams"].params[0]

            this.debug && console.log("liveparamsRaw", liveparamsRaw)

            try {
                const live = res["liveparams"] ? JSON.parse(liveparamsRaw) : null; // decode from Base64 and parse to JSON
                const data = [...this.state.data];

                data.forEach(element => {

                    if (element.title === "Configuration") {
                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "Hostname" && live && live.host) {
                        if (live.host.name && live.host.name !== "not implemented")
                            element.description = live.host.name;

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "Device ID" && live && live.host) {
                        if (live.host.deviceid && live.host.deviceid !== "not implemented")
                            element.description = live.host.deviceid;

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }
                    
                    if (element.title === "Hardware" && live && live.host) {
                        if (live.host.hwversion && live.host.hwversion !== "not implemented")
                            element.description = live.host.hwversion;

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "Location" && live && live.host) {
                        if (live.host.location && live.host.location !== "not implemented")
                            element.description = live.host.location;

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }
                    
                    if (element.title === "Date & Time" && live && live.host) {
                        if (live.host.epochtime && live.host.epochtime !== "not implemented")
                            element.description = unixToFormatted(live.host.epochtime);

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "NVR Name" && live && live.host) {
                        if (live.host.nvrname && live.host.nvrname !== "not implemented")
                            element.description = live.host.nvrname;

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }
                    
                    if (element.title === "Storage State" && live && live.storage) {
                        if (live.storage.available && live.storage.available !== "not implemented") {
                            element.description = "available"
                            element.state = "success"
                        } else {
                            element.description = "unavailable"
                            element.state = "warning"
                        }
                    }

                    if (element.title === "Storage Used/Total" && live && live.storage) {

                        const totalAutoScaled = byteConverter.autoScale((live.storage.totalbytes*8), 'b') // byte to bit
                        const totalAutoScaledFormat = totalAutoScaled.dataFormat.replace('b', 'B') // bit to byte
                        const totalConvertedRounded = Math.round(((byteConverter.convert(totalAutoScaled.value, totalAutoScaled.dataFormat, totalAutoScaledFormat)) + Number.EPSILON) * 100) / 100; 
                        const used = live.storage.totalbytes - live.storage.freebytes
                        const usedConvertedRounded = Math.round(((byteConverter.convert(used, 'B', totalAutoScaledFormat)) + Number.EPSILON) * 100) / 100;

                        element.description = usedConvertedRounded + "/" + totalConvertedRounded + totalAutoScaledFormat;
                        element.state = live.storage.available ? ((used * 100 / live.storage.totalbytes > 90) ? "warning" : "success") : "error"; // show warning >90% used
                    }

                    if (element.title === "Virtual Connection State" && live && live.vpn) {
                        if (live.vpn.connected && live.vpn.connected !== "not implemented") {
                            element.description = "connected"
                            element.state = "success"
                        } else {
                            element.description = "disconnected"
                            element.state = "warning"
                        }
                    }
                    
                    if (element.title === "Virtual Network Address" && live && live.vpn) {
                        let address = [];

                        if (live.vpn.ipv4 && live.vpn.ipv4 !== "not implemented")
                            address.push(live.vpn.ipv4)

                        if (live.vpn.ipv6 && live.vpn.ipv6 !== "not implemented")
                            address.push(live.vpn.ipv6)

                        element.description = address.join(" / ");
                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }
                    
                    if (element.title === "Ethernet Connection State" && live && live.lan) {
                        if (live.lan.connected && live.lan.connected !== "not implemented") {
                            element.description = "connected"
                            element.state = "success"
                        } else {
                            element.description = "disconnected"
                            element.state = "warning"
                        }
                    }
                    
                    if (element.title === "Ethernet Network Address" && live && live.lan) {
                        let address = [];

                        if (live.lan.ipv4 && live.lan.ipv4 !== "not implemented")
                            address.push(live.lan.ipv4)

                        if (live.lan.ipv6 && live.lan.ipv6 !== "not implemented")
                            address.push(live.lan.ipv6)

                        element.description = address.join(" / ");
                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }
                    
                    if (element.title === "Wireless Connection State" && live && live.wlan) {
                        let connected = [];

                        if (live.wlan.connected && live.wlan.connected !== "not implemented") {
                            connected.push("connected")
                            element.state = "success"
                        } else {
                            connected.push("disconnected")
                            element.state = "warning"
                        }

                        if (live.wlan.ssid && live.wlan.ssid !== "not implemented")
                            connected.push("(" + live.wlan.ssid + ")")

                        element.description = connected.join(" ");
                    }
                    
                    if (element.title === "Wireless Network Address" && live && live.wlan) {
                        let address = [];

                        if (live.wlan.ipv4 && live.wlan.ipv4 !== "not implemented")
                            address.push(live.wlan.ipv4)

                        if (live.wlan.ipv6 && live.wlan.ipv6 !== "not implemented")
                            address.push(live.wlan.ipv6)

                        element.description = address.join(" / ");
                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "Mobile Connection State" && live && live.mobile) {
                        let connected = [];

                        if (live.mobile.connected && live.mobile.connected !== "not implemented") {
                            connected.push("connected")
                            element.state = "success";
                        } else {
                            connected.push("disconnected")
                            element.state = "warning";
                        }

                        if (live.mobile.conntype && live.mobile.conntype !== "not implemented")
                            connected.push("(" + live.mobile.conntype.toUpperCase() + ")")

                        element.description = connected.join(" ");
                    }
                    
                    if (element.title === "Mobile Network Address" && live && live.mobile) {
                        let address = [];

                        if (live.mobile.ipv4 && live.mobile.ipv4 !== "not implemented")
                            address.push(live.mobile.ipv4)

                        if (live.mobile.ipv6 && live.mobile.ipv6 !== "not implemented")
                            address.push(live.mobile.ipv6)

                        element.description = address.join(" / ");
                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "Mobile Signal Strength" && live && live.mobile) {
                        if (live.mobile.signalquality && live.mobile.signalquality !== "not implemented")
                            element.description = live.mobile.signalquality + " dBm"

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }
					
					if (element.title === "Mobile Modem Firmware" && live && live.mobile) {
						if (live.mobile.firmware && (live.mobile.firmware !== "not implemented")) {
							element.description = live.mobile.firmware;
							element.state = "success";
						}
						else {
							element.description = "Unknown";
							element.state = "warning";
						}
                    }

                    if (element.title === "Location (GPS)" && live && live.gps) {
                        if (live.gps.longitude && live.gps.latitude)
                        {
                            let longitude = extractCoordinate(live.gps.longitude, true)
                            let latitude = extractCoordinate(live.gps.latitude, false)
                            element.description = [latitude, longitude].join(" ");
                        }

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (element.title === "Media Backup") {
                        element.state = "success";
                    }

                    if (element.title === "Firmware" && live && live.host) {
                        var fwversion = live.host.fwversion
                        if (fwversion && fwversion !== "not implemented") {
                            element.description = `${fwversion} - ${this.state.updateStatus || "you are running the latest version"}`;
                        }

                        element.state = element.description && element.description !== "-" ? "success" : "warning";
                    }

                    if (!element.description || element.description === "not implemented") {
                        element.description = "-"
                    }
                })

                if (this.debug) {
                    console.log("liveparamsParsed", live)
                    console.log("liveparamsProcessed", data)
                }

                this.setState({ data })
            }
            catch (error) {
                console.error(error)
                this.setState({ error })
            }
        })
        .catch(error => {
            console.error(error)
            this.setState({ error })
        })
        .finally(() => this.setState({ isLoaded: true }))
    }

    render() {

        const { error, data, isLoaded, isConfigModalLoaded, isConfigModalVisible, config, modalError, isStorageModalVisible, isStorageModalLoaded, disableInstallUpdate, filenameToUpdate } = this.state;
        const { access, unit: { proxyKey}, form: { getFieldDecorator }  } = this.props
        const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER, PROCESS_UPLOAD, DEBUG } } = window

        const isUser = access && access === "user"
        const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
        const server = BUILD ? location.hostname : SERVER;
        const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
        const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
        const upload = PROCESS_UPLOAD ?? "/process-upload";

        const uploadConfig = protocol + server + port + path + upload;

        DEBUG && console.log("uploadConfig", uploadConfig)

        return (
            <Layout>
                <Layout.Content style={{ marginTop: "48px" }}>
                    {error ? (
                        <Status is={error} back={MAINTENANCE} />
                    ) : (
                        <Row style={{ overflowX: "hidden" }}>                    
                            <List
                                split={false}
                                rowKey={item => item.title}
                                size="small"
                                dataSource={data}
                                loading={!isLoaded}
                                itemLayout="vertical"
                                renderItem={item => 
                                    <Col 
                                        xs={{ span: 24 }}
                                        lg={{ span: 12 }}
                                    >
                                        <List.Item className='listItem'>

                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.description}
                                                avatar={<Avatar shape="square" icon={item.avatar} style={ 
                                                    item.state === "warning" ? { color: "#f56a00", backgroundColor: '#fde3cf' } 
                                                    : item.state === "error" ? { color: '#EE3350', backgroundColor: '#fccfcf' }
                                                    : item.state ==="success" ? { color: "green", backgroundColor: "#cbf2cb" }
                                                    : undefined } />}
                                            />

                                            {item.actions && 
                                            <Col className='listButtonContainer'>
                                                {item.actions.map((elem, index) => {
                                                    let actionComponent = null
                                                    const isInstallUpdateDisabled = item.title === "Firmware" && (disableInstallUpdate || !filenameToUpdate?.length)

                                                    if (isUser) {
                                                        return actionComponent
                                                    }

                                                    switch (elem.name) {

                                                        case "Import":
                                                            actionComponent = (
                                                                <Upload
                                                                    key={index}
                                                                    showUploadList={false}
                                                                    accept={elem.accept}
                                                                    beforeUpload={elem.beforeUpload}
                                                                    className="listButton"
                                                                >
                                                                    <Button
                                                                        type={item.state === "error" ? "primary" : null}
                                                                        icon={elem.icon}
                                                                        disabled={!item.description || item.description === "-"}
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        {elem.name}
                                                                    </Button>
                                                                </Upload>
                                                            )
                                                            break;

                                                        case "Install from uploaded file":
                                                        case "Restore":
                                                            actionComponent = (
                                                                <Upload
                                                                    key={index}
                                                                    showUploadList={false}
                                                                    accept={elem.accept}
                                                                    action={uploadConfig}
                                                                    onChange={info => this.upload(info, elem.name)}
                                                                    className="listButton"
                                                                >
                                                                    <Button
                                                                        type={item.state === "error" ? "primary" : null}
                                                                        icon={elem.icon}
                                                                        disabled={!item.description || item.description === "-"}
                                                                        style={{ width: "100%" }}
                                                                    >
                                                                        {elem.name}
                                                                    </Button>
                                                                </Upload>
                                                            );
                                                            break;

                                                        case "Export":
                                                        case "Backup":
                                                        case "Reboot":
                                                        case "Shutdown":
                                                        case "Format":
                                                        case "Check update status":
                                                            actionComponent = (
                                                                <Button
                                                                    key={index}
                                                                    type={item.state === "error" ? "primary" : null}
                                                                    icon={elem.icon}
                                                                    disabled={!item.description || item.description === "-"}
                                                                    onClick={elem.onClick}
                                                                    className="listButton"
                                                                >
                                                                    {elem.name}
                                                                </Button>
                                                            );
                                                            break;

                                                        case "Start":
                                                            actionComponent = (
                                                                <Button
                                                                    key={index}
                                                                    type={item.state === "error" ? "primary" : null}
                                                                    icon={elem.icon}
                                                                    onClick={elem.onClick}
                                                                    className="listButton"
                                                                >
                                                                    {elem.name}
                                                                </Button>
                                                            );
                                                            break;
                                                        
                                                        case "Install available update":
                                                            actionComponent = (
                                                                <Button
                                                                    key={index}
                                                                    type={item.state === "error" ? "primary" : null}
                                                                    icon={elem.icon}
                                                                    disabled={isInstallUpdateDisabled}
                                                                    onClick={elem.onClick}
                                                                    className="listButton"
                                                                >
                                                                    {elem.name}
                                                                </Button>
                                                            );
                                                            break;

                                                        case "Prepare and clean external storage first":
                                                            actionComponent = (
                                                                <Checkbox
                                                                    key={index}
                                                                    onChange={this.onChangeCheckbox} 
                                                                    className="listButton"
                                                                >
                                                                    {elem.name}
                                                                </Checkbox>
                                                            )
                                                            break;

                                                        default:
                                                            actionComponent = null;
                                                    }

                                                    return actionComponent;
                                                })}
                                            </Col>}
                                            
                                        </List.Item>
                                    </Col>
                                }
                            />

                            <Modal
                                width="750px"
                                centered
                                closable={false}
                                visible={isConfigModalVisible}
                                onOk={this.confirmImport}
                                onCancel={this.cancelModal}
                                okText="Confirm"
                                confirmLoading={!isConfigModalLoaded}
                                destroyOnClose
                            >
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('config', {
                                        onChange: this.configChange,
                                        initialValue: config
                                    })(
                                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 12 }} disabled={!isConfigModalLoaded} />  
                                    )}
                                </Form.Item>

                                {modalError ? <div style={{ marginTop: "12px", color: "red" }}>{modalError}</div> : null}
                                
                            </Modal>

                            <Modal
                                width="750px"
                                centered
                                closable={false}
                                visible={isStorageModalVisible}
                                onOk={this.confirmFormat}
                                onCancel={this.cancelModal}
                                okText="Confirm"
                                confirmLoading={!isStorageModalLoaded}
                                destroyOnClose
                            >

                                <Form 
                                    labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} 
                                    wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }}
                                >

                                    <Form.Item 
                                        label="Encryption Password" 
                                        style={{ marginBottom: 0 }} 
                                        // help={"A reboot of the device is necessary for the changes to take effect!"}
                                    >
                                        {getFieldDecorator('storageEncryptionPassword', {
                                            onChange: this.storageEncryptionPasswordChange,
                                            rules: [{ pattern: NAME, message: NAME_MSG }]
                                        })(
                                            <Input.Password disabled={!isConfigModalLoaded} />
                                        )}
                                    </Form.Item>

                                </Form>
                            </Modal>
                        </Row>
                    )}
                </Layout.Content>
            </Layout>
        )
    }
}

export default Form.create() (connect(mapStateToProps, mapDispatchToProps)(Maintenance));