import React from 'react';
import ConfirmButton from '../../controls/ConfirmButton';
import * as REGEX from "../../../constants/regex";
import { Form, Input, InputNumber, Button, Icon, Radio, Collapse, Upload, message, Popconfirm, Table, Modal, Divider, Select, List } from 'antd';
import { connect } from "react-redux";
import { left, select } from "../../../api/actions";
import { NUMBER, NUMBER_MSG, NAME, NAME_MSG } from '../../../constants/regex';
import { unixToFormatted } from '../../Utilities';

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

 function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        currentLeft: state.currentLeft,
        access: state.access
    };
}

class ConnectivityForm extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            dhcpState: false,
            isLoaded: false,
            isWlanModalLoaded: true,
            isWlanModalVisible: false,
            isTimeModalLoaded: true,
            isTimeModalVisible: false,
            isHeitelModalLoaded: true,
            isHeitelModalVisible: false,
            isRuleModalLoaded: true,
            isPortForwardingModalVisible: false,
            heitelState: false,
            heitelServers: [],
            lanState: true,
            lanV4Mode: "dhcp",
            lanV6Mode: "dhcp",
            mediaRetainment: 4,
            mediaRetainmentUnit: "weeks",
            mobileState: false,
            mobileSim: 0,
            notificationRetainment: 4,
            notificationRetainmentUnit: "weeks",
            portForwardings: [],
            portForwardingState: true,
            smtpEnc: false,
            smtpEnc2: false,
            smtpEnc3: false,
            vpnState: false,
            vpnClientMode: "dhcp",
            webrtcStunServer: "stun:stun1.l.google.com:19302",
            wlanState: false,
            wlanMode: true,
            wlanHidden: false,
            wlanNetworks: [],
            timeServers: []
        };

        this.getConfig = this.getConfig.bind(this);
        this.getCertificates = this.getCertificates.bind(this);
        this.debug = window.config.DEBUG
    }

    componentDidMount() {
        this.props.select(null)
        this.props.left("connectivity"); // set initials in redux store
        this.setState({ isLoaded: false }, () => {
            this.getConfig()
            this.getCertificates()
            this.setState({ isLoaded: true })
        });
    }

    changeDhcpState = value => this.setState({ dhcpState: value.target.value });
    changeDhcpRangeStart = value => this.setState({ dhcpRangeStart: value.target.value });
    changeDhcpRangeEnd = value => this.setState({ dhcpRangeEnd: value.target.value });

    changeSmtpServer = value => this.setState({ smtpServer: value.target.value });
    changeSmtpUsername = value => this.setState({ smtpUsername: value.target.value });
    changeSmtpPassword = value => this.setState({ smtpPassword: value.target.value });
    changeSmtpEnc = value => this.setState({ smtpEnc: value.target.value });
    changeSmtpSender = value => this.setState({ smtpSender: value.target.value });

    changeSmtpServer2 = value => this.setState({ smtpServer2: value.target.value });
    changeSmtpUsername2 = value => this.setState({ smtpUsername2: value.target.value });
    changeSmtpPassword2 = value => this.setState({ smtpPassword2: value.target.value });
    changeSmtpEnc2 = value => this.setState({ smtpEnc2: value.target.value });
    changeSmtpSender2 = value => this.setState({ smtpSender2: value.target.value });

    changeSmtpServer3 = value => this.setState({ smtpServer3: value.target.value });
    changeSmtpUsername3 = value => this.setState({ smtpUsername3: value.target.value });
    changeSmtpPassword3 = value => this.setState({ smtpPassword3: value.target.value });
    changeSmtpEnc3 = value => this.setState({ smtpEnc3: value.target.value });
    changeSmtpSender3 = value => this.setState({ smtpSender3: value.target.value });

    changeHeitelState = value => this.setState({ heitelState: value.target.value });
    changeHeitelPassword = value => this.setState({ heitelPassword: value.target.value });
    changeHeitelPort = value => this.setState({ heitelPort: value });
    changeHeitelServerName = value => this.setState({ heitelServerName: value.target.value });

    changeOnvifPassword = value => this.setState({ onvifPassword: value.target.value });

    changeVpnState = value => this.setState({ vpnState: value.target.value });
    changeVpnServer = value => this.setState({ vpnServer: value.target.value });
    changeVpnUsername = value => this.setState({ vpnUsername: value.target.value });
    changeVpnPassword = value => this.setState({ vpnPassword: value.target.value });
    changeVpnClientMode = value => this.setState({ vpnClientMode: value.target.value });
    changeVpnClientAddress = value => this.setState({ vpnClientAddress: value.target.value });
    changeVpnPingDst = value => this.setState({ vpnPingDst: value.target.value });

    changeLanState = value => this.setState({ lanState: value.target.value });
    changelanV4Mode = value => this.setState({ lanV4Mode: value.target.value });
    changeLanV4Address = value => this.setState({ lanV4Address: value.target.value });
    changeLanV4Gateway = value => this.setState({ lanV4Gateway: value.target.value });
    changeLanV4Dns = value => this.setState({ lanV4Dns: value.target.value });
    changelanV6Mode = value => this.setState({ lanV6Mode: value.target.value });
    changeLanV6Address = value => this.setState({ lanV6Address: value.target.value });
    changeLanV6Gateway = value => this.setState({ lanV6Gateway: value.target.value });
    changeLanV6Dns = value => this.setState({ lanV6Dns: value.target.value });
    
    changeTimeServerName = value => this.setState({ timeServerName: value.target.value });

    changeWlanState = value => this.setState({ wlanState: value.target.value });
    changeWlanName = value => this.setState({ wlanName: value.target.value });
    changeWlanPassword = value => this.setState({ wlanPassword: value.target.value });
    changeWlanHidden = value => this.setState({ wlanHidden: value.target.value });
    changeWlanRadiusServer = value => this.setState({ wlanRadiusServer: value.target.value });
    changeWlanRadiusUsername = value => this.setState({ wlanRadiusUsername: value.target.value });
    changeWlanRadiusPassword = value => this.setState({ wlanRadiusPassword: value.target.value });
    changeWlanMode = value => this.setState({ wlanMode: value.target.value });
    changeWlanAccesspointPassword = value => this.setState({ wlanAccesspointPassword: value.target.value });

    changeMobileState = value => this.setState({ mobileState: value.target.value });
    changeMobileSim = value => this.setState({ mobileSim: value.target.value });
    changeMobileApn = value => this.setState({ mobileApn: value.target.value });
    changeMobilePin = value => this.setState({ mobilePin: value });
    changeMobileUsername = value => this.setState({ mobileUsername: value.target.value });
    changeMobilePassword = value => this.setState({ mobilePassword: value.target.value });
    changeMobilePing = value => this.setState({ mobilePing: value.target.value });
    changeMobileApn2 = value => this.setState({ mobileApn2: value.target.value });
    changeMobilePin2 = value => this.setState({ mobilePin2: value });
    changeMobileUsername2 = value => this.setState({ mobileUsername2: value.target.value });
    changeMobilePassword2 = value => this.setState({ mobilePassword2: value.target.value });
    changeMobilePing2 = value => this.setState({ mobilePing2: value.target.value });

    changeNvrName = value => this.setState({ nvrName: value.target.value })

    changeOutboundRuleAddress = value => this.setState({ outboundRuleAddress: value.target.value })
    changePortForwardingState = value => this.setState({ portForwardingState: value.target.value });
    changePortForwardingName = value => this.setState({ portForwardingName: value.target.value })
    changePortForwardingPublicPort = value => this.setState({ portForwardingPublicPort: value })
    changePortForwardingAddress = value => this.setState({ portForwardingAddress: value.target.value })
    changePortForwardingPrivatePort = value => this.setState({ portForwardingPrivatePort: value })
    
    changeWebrtcServer = value => this.setState({ webrtcServer: value.target.value })
    changeWebrtcStunServer = value => this.setState({ webrtcStunServer: value.target.value })
    changeWebrtcTurnServer = value => this.setState({ webrtcTurnServer: value.target.value })
    changeWebrtcTurnUsername = value => this.setState({ webrtcTurnUsername: value.target.value })
    changeWebrtcTurnPassword = value => this.setState({ webrtcTurnPassword: value.target.value })

    changeVcaServer = value => this.setState({ vcaServer: value.target.value });

    changeMediaRetainment = value => this.setState({ mediaRetainment: value.target.value })
    changeMediaRetainmentUnit = value =>  this.setState({ mediaRetainmentUnit: value })

    changeNotificationRetainment = value => this.setState({ notificationRetainment: value.target.value })
    changeNotificationRetainmentUnit = value =>  this.setState({ notificationRetainmentUnit: value })

    changeAzureAccount = value => this.setState({ azureAccount: value.target.value })
    changeAzureKey = value => this.setState({ azureKey: value.target.value })
    changeOidServer = value => this.setState({ oidServer: value.target.value })

    getConfig = () => {

        this.props.processMessage([{ 
            request: "configuration",
            method: "get",
            params: []
        }])
        .then(res => {
            try {
                const settings = JSON.parse(atob(res["configuration"]?.params?.[0]?.settings))?.devparams; // decode from Base64 and parse to JSON

                if (this.debug) {
                    console.log("Settings", settings)
                }

                if (settings?.lan) {

                    const lan = settings.lan;
                    
                    this.setState({ 
                        lanState: lan.enable ? lan.enable : false 
                    })
                    
                    if (lan.ipv4) {
                        
                        const ipv4 = lan.ipv4;
                        
                        this.setState({
                            lanV4Mode: ipv4.mode,
                            lanV4Address: ipv4.ip ? ipv4.ip : null,
                            lanV4Gateway: ipv4.gateway ? ipv4.gateway : null,
                            lanV4Dns: ipv4.dns ? ipv4.dns : null,
                        })
                    }
                    
                    if (lan.ipv6) {
                        
                        const ipv6 = lan.ipv6;
                        
                        this.setState({
                            lanV6Mode: ipv6.mode,
                            lanV6Address: ipv6.ip ? ipv6.ip : null,
                            lanV6Gateway: ipv6.gateway ? ipv6.gateway : null,
                            lanV6Dns: ipv6.dns ? ipv6.dns : null,
                        })
                    }
                    
                    if (lan.ipv4dhcp) {
                        
                        const ipv4dhcp = lan.ipv4dhcp;
                        
                        this.setState({
                            dhcpState: ipv4dhcp.enable ? ipv4dhcp.enable : false,
                            dhcpRangeStart: ipv4dhcp.iprangefirst ? ipv4dhcp.iprangefirst : null,
                            dhcpRangeEnd: ipv4dhcp.iprangelast ? ipv4dhcp.iprangelast : null,
                        })
                    }
                }

                if (settings?.vpn) {

                    const vpn = settings.vpn;

                    this.setState({
                        vpnState: vpn.enable ? vpn.enable : false,
                        vpnServer: vpn.host ? vpn.host : null,
                        vpnUsername: vpn.user ? vpn.user : null,
                        vpnPassword: vpn.password ? vpn.password : null
                    })

                    if (vpn.netconf) {

                        const netconf = vpn.netconf;

                        this.setState({
                            vpnClientMode: netconf.mode ? netconf.mode : null,
                            vpnClientAddress: netconf.ip ? netconf.ip : null,
                            vpnPingDst: netconf.gateway ? netconf.gateway : null
                        })
                    }
                }

                if (settings?.wlan) {

                    const wlan = settings.wlan;
                    
                    this.setState({ 
                        wlanState: wlan.enable ? wlan.enable : false,
                        wlanNetworks: wlan.networks ? wlan.networks : [],
                        wlanMode: wlan.infrastructure ? wlan.infrastructure : false,
                        wlanAccesspointPassword: wlan.appassword ? wlan.appassword : null
                    })
                }

                if (settings?.mobile) {

                    const mobile = settings.mobile;

                    this.setState({ 
                        mobileState: mobile.enable ? mobile.enable : false,
                        mobileApn: mobile.apn ? mobile.apn : null,
                        mobilePin: mobile.simpin ? Number(mobile.simpin) : null,
                        mobileUsername: mobile.user ? mobile.user : null,
                        mobilePassword: mobile.password ? mobile.password : null,
                        mobilePing: mobile.pingdst ? mobile.pingdst : null,
                        mobileApn2: mobile.apn2 ? mobile.apn2 : null,
                        mobilePin2: mobile.simpin2 ? Number(mobile.simpin2) : null,
                        mobileUsername2: mobile.user2 ? mobile.user2 : null,
                        mobilePassword2: mobile.password2 ? mobile.password2 : null,
                        mobilePing2: mobile.pingdst2 ? mobile.pingdst2 : null,
                        mobileSim: mobile.sim ? 1 : 0
                    })
                }

                if (settings?.time) {

                    const time = settings.time;

                    this.setState({ 
                        timeServers: time.ntpservers ? time.ntpservers.map(elem => ({ name: elem })) : [], // from ["value"] to [{"key":"value"}]
                    })
                }

                if (settings?.heitel) {

                    const heitel = settings.heitel;

                    this.setState({ 
                        heitelState: heitel.enable ? heitel.enable : false,
                        heitelPort: heitel.port ? heitel.port : 0,
                        heitelPassword: heitel.password ? heitel.password : null,
                        heitelServers: heitel.alarmservers ? heitel.alarmservers.map(elem => ({ name: elem })) : [] // from ["value"] to [{"key":"value"}]
                    })
                }

                if (settings?.smtp) {

                    const smtp = settings.smtp;

                    this.setState({ 
                        smtpServer: smtp.smtpserver ? smtp.smtpserver : null,
                        smtpEnc: smtp.ssltls ? smtp.ssltls : false,
                        smtpUsername: smtp.user ? smtp.user : null,
                        smtpPassword: smtp.password ? smtp.password : null,
                        smtpSender: smtp.sender ? smtp.sender : null,

                        smtpServer2: smtp.sentinelsmtpserver ? smtp.sentinelsmtpserver : null,
                        smtpEnc2: smtp.sentinelssltls ? smtp.sentinelssltls : false,
                        smtpUsername2: smtp.sentineluser ? smtp.sentineluser : null,
                        smtpPassword2: smtp.sentinelpassword ? smtp.sentinelpassword : null,
                        smtpSender2: smtp.sentinelsender ? smtp.sentinelsender : null,

                        smtpServer3: smtp.sureviewsmtpserver ? smtp.sureviewsmtpserver : null,
                        smtpEnc3: smtp.sureviewssltls ? smtp.sureviewssltls : false,
                        smtpUsername3: smtp.sureviewuser ? smtp.sureviewuser : null,
                        smtpPassword3: smtp.sureviewpassword ? smtp.sureviewpassword : null,
                        smtpSender3: smtp.sureviewsender ? smtp.sureviewsender : null
                    })
                }

                if (settings?.onvif) {

                    const onvif = settings.onvif;
                    
                    this.setState({ 
                        onvifMode: true,
                        onvifPassword: onvif.adminpassword ? onvif.adminpassword : null
                    })
                }

                if (settings?.portforwarding) {

                    const portforwarding = settings.portforwarding

                    this.setState({ 
                        portForwardings: portforwarding.rules?.length ? portforwarding.rules : []
                    })
                }

                if (settings?.webrtc) {

                    const webrtc = settings.webrtc
                    
                    this.setState({ 
                        webrtcServer: webrtc.server ? webrtc.server : null,
                        webrtcStunServer: webrtc.iceservers?.stun?.uri ? webrtc.iceservers.stun.uri : null,
                        webrtcTurnServer: webrtc.iceservers?.turn?.uri ? webrtc.iceservers.turn.uri : null,
                        webrtcTurnUsername: webrtc.iceservers?.turn?.user ? webrtc.iceservers.turn.user : null,
                        webrtcTurnPassword: webrtc.iceservers?.turn?.password ? webrtc.iceservers.turn.password : null
                    })
                }

                if (settings?.host) {

                    const host = settings.host

                    this.setState({ 
                        nvrName: host.nvrname ? host.nvrname : null
                    })

                    if (host.mediaRetainment) {
                        switch (true) {
                            case (host.mediaRetainment >= 31536000): // 1 Jahr in Sekunden
                                this.setState({ 
                                    mediaRetainment: host.mediaRetainment/31536000, // seconds to years
                                    mediaRetainmentUnit: "years" 
                                });
                                break;
                            case (host.mediaRetainment < 31536000 && host.mediaRetainment >= 604800):
                                this.setState({ 
                                    mediaRetainment: host.mediaRetainment/604800, // seconds to weeks
                                    mediaRetainmentUnit: "weeks" 
                                });
                                break;
                            case (host.mediaRetainment < 604800 && host.mediaRetainment >= 86400):
                                this.setState({ 
                                    mediaRetainment: host.mediaRetainment/86400, // seconds to days
                                    mediaRetainmentUnit: "days" 
                                });
                                break;
                            case (host.mediaRetainment < 86400):
                                this.setState({ 
                                    mediaRetainment: host.mediaRetainment/3600, // seconds to hours
                                    mediaRetainmentUnit: "hours" 
                                });
                                break;
                            default:
                                break;
                        }
                    }
                    
                    if (host.notificationRetainment) {
                        switch (true) {
                            case (host.notificationRetainment >= 31536000):
                                this.setState({ 
                                    notificationRetainment: host.notificationRetainment/31536000, // seconds to years
                                    notificationRetainmentUnit: "years" 
                                });
                                break;
                            case (host.notificationRetainment < 31536000 && host.notificationRetainment >= 604800):
                                this.setState({ 
                                    notificationRetainment: host.notificationRetainment/604800, // seconds to weeks
                                    notificationRetainmentUnit: "weeks" 
                                });
                                break;
                            case (host.notificationRetainment < 604800 && host.notificationRetainment >= 86400):
                                this.setState({ 
                                    notificationRetainment: host.notificationRetainment/86400, // seconds to days
                                    notificationRetainmentUnit: "days" 
                                });
                                break;
                            case (host.notificationRetainment < 86400):
                                this.setState({ 
                                    notificationRetainment: host.notificationRetainment/3600, // seconds to hours
                                    notificationRetainmentUnit: "hours" 
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }

                if (settings?.analytics) {

                    const vca = settings.analytics

                    this.setState({ 
                        vcaServer: vca.server ? vca.server : null
                    })
                }

                if (settings?.cloud) {

                    const cloud = settings.cloud

                    this.setState({ 
                        azureAccount: cloud.account ? cloud.account : null,
                        azureKey: cloud.key ? cloud.key : null,
                        oidServer: cloud.oidserver ? cloud.oidserver : null
                    })
                }

            } catch (error) {
                console.error(error);
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.");
            }
        })
        .catch(error => {
            console.error(error);
            message.error(error 
                ? typeof error.msg === "undefined" 
                    ? error.toString() 
                    : typeof error.msg === "object" 
                        ? error.msg.message 
                        : error.msg
                : "Unknown Error. Please try again.");
        })
        
        // prepared for multiple certificates
        // var httpsCertificates = [{ id: 1, name: "cert1.pem", content: "123"}]
        // httpsCertificates.forEach(elem => elem["uid"] = elem.id); // set id to uid (necessary for Upload component)
        // this.setState({ httpsCertificates });
    }
    
    getCertificates = () => {
        this.props.processMessage([{ 
            request: "certificates",
            method: "get",
            params: [{ "purpose": "" }]
        }])
        .then(res => {
            try {
                let certificates = res["certificates"]?.params;

                if (certificates) {

                    certificates = {
                        trusted: certificates.filter(cert => cert.purpose.includes('TrustedPubic')),
                        vpn: certificates.filter(cert => cert.purpose.includes('VPN'))
                    }

                    if (this.debug) {
                        console.log("Certificates", certificates)
                    }

                    this.setState({ certificates })
                }
            } catch (error) {
                console.error(error);
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.");
            }
        })
        .catch(error => {
            console.error(error);
            message.error(error 
                ? typeof error.msg === "undefined" 
                    ? error.toString() 
                    : typeof error.msg === "object" 
                        ? error.msg.message 
                        : error.msg
                : "Unknown Error. Please try again.");
        })
    }

    confirm = () => {

        this.setState({ isLoaded: false }, () => {

            const { 
                portForwardings, 
                webrtcServer, 
                webrtcStunServer, 
                webrtcTurnServer, 
                webrtcTurnUsername, 
                webrtcTurnPassword, 
                nvrName, 
                vcaServer,
                mediaRetainment, 
                mediaRetainmentUnit, 
                notificationRetainment, 
                notificationRetainmentUnit, 
                azureAccount, 
                azureKey,
                oidServer,
                vpnPingDst
            } = this.state

            var req = []

            // if (this.state.publicCertificate) {
            //     req.push({
            //         request: "certificates",
            //         method: "set",
            //         params: [this.state.publicCertificate]
            //     })
            // }

            // if (this.state.privateCertificate) {
            //     req.push({
            //         request: "certificates",
            //         method: "set",
            //         params: [this.state.privateCertificate]
            //     })
            // }

            let mediaRetainmentSec = 0
            let notficiationRetainmentSec = 0

            if (mediaRetainment) {
                switch (mediaRetainmentUnit) {
                    case "minutes":
                        mediaRetainmentSec = parseFloat(mediaRetainment)*60
                        break;
                    case "hours":
                        mediaRetainmentSec = parseFloat(mediaRetainment)*3600
                        break;
                    case "days":
                        mediaRetainmentSec = parseFloat(mediaRetainment)*86400
                        break;
                    case "weeks":
                        mediaRetainmentSec = parseFloat(mediaRetainment)*604800
                        break;
                    case "years":
                        mediaRetainmentSec = parseFloat(mediaRetainment)*31536000
                        break;
                    default:
                        break;
                }
            }

            if (notificationRetainment) {
                switch (notificationRetainmentUnit) {
                    case "minutes":
                        notficiationRetainmentSec = parseFloat(notificationRetainment)*60
                        break;
                    case "hours":
                        notficiationRetainmentSec = parseFloat(notificationRetainment)*3600
                        break;
                    case "days":
                        notficiationRetainmentSec = parseFloat(notificationRetainment)*86400
                        break;
                    case "weeks":
                        notficiationRetainmentSec = parseFloat(notificationRetainment)*604800
                        break;
                    case "years":
                        notficiationRetainmentSec = parseFloat(notificationRetainment)*31536000
                        break;
                    default:
                        break;
                }
            }

            var settings = {
                devparams: {
                    lan: {
                        enable: this.state.lanState, // switch will never be empty
                        ipv4: {
                            mode: this.state.lanV4Mode,
                            ip: this.state.lanV4Address ? this.state.lanV4Address : "",
                            gateway: this.state.lanV4Gateway ? this.state.lanV4Gateway : "",
                            dns: this.state.lanV4Dns ? this.state.lanV4Dns : ""
                        },
                        ipv6: {
                            mode: this.state.lanV6Mode,
                            ip: this.state.lanV6Address ? this.state.lanV6Address : "",
                            gateway: this.state.lanV6Gateway ? this.state.lanV6Gateway : "",
                            dns: this.state.lanV6Dns ? this.state.lanV6Dns : ""
                        },
                        ipv4dhcp: {
                            enable: this.state.dhcpState, // switch will never be empty
                            iprangefirst: this.state.dhcpRangeStart ? this.state.dhcpRangeStart : "",
                            iprangelast: this.state.dhcpRangeEnd ? this.state.dhcpRangeEnd : ""
                        }
                    },
                    vpn: {
                        enable: this.state.vpnState, // switch will never be empty
                        host: this.state.vpnServer ? this.state.vpnServer : "",
                        user: this.state.vpnUsername ? this.state.vpnUsername : "",
                        password: this.state.vpnPassword ? this.state.vpnPassword : "",
                        netconf: {
                            mode: this.state.vpnClientMode, // switch will never be empty
                            ip: this.state.vpnClientAddress ? this.state.vpnClientAddress : "",
                            gateway: vpnPingDst ? vpnPingDst : "", // used for ping host
                            dns: "" // not used by vpn
                        }
                    },
                    wlan: {
                        enable: this.state.wlanState, // switch will never be empty
                        infrastructure: this.state.wlanMode, // false means accesspoint mode
                        appassword: this.state.wlanAccesspointPassword ? this.state.wlanAccesspointPassword : "", // accesspoint mode only, cannot be empty!
                        networks: this.state.wlanNetworks ? this.state.wlanNetworks : []
                    },
                    mobile: {
                        enable: this.state.mobileState, // switch will never be empty
                        sim: this.state.mobileSim ? 1 : 0,

                        apn: this.state.mobileApn ? this.state.mobileApn : "",
                        simpin: this.state.mobilePin ? this.state.mobilePin.toString() : "",
                        user: this.state.mobileUsername ? this.state.mobileUsername : "",
                        password: this.state.mobilePassword ? this.state.mobilePassword : "",
                        pingdst: this.state.mobilePing ? this.state.mobilePing : "",

                        apn2: this.state.mobileApn2 ? this.state.mobileApn2 : "",
                        simpin2: this.state.mobilePin2 ? this.state.mobilePin2.toString() : "",
                        user2: this.state.mobileUsername2 ? this.state.mobileUsername2 : "",
                        password2: this.state.mobilePassword2 ? this.state.mobilePassword2 : "",
                        pingdst2: this.state.mobilePing2 ? this.state.mobilePing2 : ""
                    },
                    heitel: {
                        enable: this.state.heitelState, // switch will never be empty
                        port: this.state.heitelPort ? this.state.heitelPort : 0,
                        password: this.state.heitelPassword ? this.state.heitelPassword : "",
                        alarmservers: this.state.heitelServers ? this.state.heitelServers.map(elem => elem.name) : [] // from [{"key":"value"}] to ["value"]
                    },
                    smtp: {
                        smtpserver: this.state.smtpServer ? this.state.smtpServer : "",
                        ssltls: this.state.smtpEnc, // switch will never be empty
                        user: this.state.smtpUsername ? this.state.smtpUsername : "",
                        password: this.state.smtpPassword ? this.state.smtpPassword : "",
                        sender: this.state.smtpSender ? this.state.smtpSender : "",

                        sentinelsmtpserver: this.state.smtpServer2 ? this.state.smtpServer2 : "",
                        sentinelssltls: this.state.smtpEnc2, // switch will never be empty
                        sentineluser: this.state.smtpUsername2 ? this.state.smtpUsername2 : "",
                        sentinelpassword: this.state.smtpPassword2 ? this.state.smtpPassword2 : "",
                        sentinelsender: this.state.smtpSender2 ? this.state.smtpSender2 : "",

                        sureviewsmtpserver: this.state.smtpServer3 ? this.state.smtpServer3 : "",
                        sureviewssltls: this.state.smtpEnc3, // switch will never be empty
                        sureviewuser: this.state.smtpUsername3 ? this.state.smtpUsername3 : "",
                        sureviewpassword: this.state.smtpPassword3 ? this.state.smtpPassword3 : "",
                        sureviewsender: this.state.smtpSender3 ? this.state.smtpSender3 : "",
                    },
                    time: {
                        ntpservers: this.state.timeServers ? this.state.timeServers.map(elem => elem.name) : [] // from [{"key":"value"}] to ["value"]
                    },
                    onvif: {
                        enable: true,
                        adminpassword: this.state.onvifPassword ? this.state.onvifPassword : ""
                    },
                    webrtc: {
                        server: webrtcServer ? webrtcServer : "",
                        iceservers: {
                            stun: {
                                uri: webrtcStunServer ? webrtcStunServer : "",
                            },
                            turn: {
                                uri: webrtcTurnServer ? webrtcTurnServer : "",
                                user: webrtcTurnUsername ? webrtcTurnUsername : "",
                                password: webrtcTurnPassword ? webrtcTurnPassword : ""
                            }
                        }
                    },
                    portforwarding: {
                        rules: portForwardings
                    },
                    host: {
                        nvrname: nvrName ? nvrName : "",
                        mediaRetainment: mediaRetainmentSec ? parseInt(mediaRetainmentSec) : 0,
                        notificationRetainment: notficiationRetainmentSec ? parseInt(notficiationRetainmentSec) : 0
                    },
                    analytics: {
                        server: vcaServer ? vcaServer : ""
                    },
                    cloud: {
                        account: azureAccount ? azureAccount : "",
                        key: azureAccount && azureKey ? azureKey : "",
                        oidserver: oidServer ? oidServer : ""
                    }
                }
            }

            if (this.state.vpnCertificateName && this.state.vpnCertificate)
                settings.devparams.vpn.hostcertificate = {
                    filename: this.state.vpnCertificateName,
                    content: this.state.vpnCertificate
                }

            if (this.debug)
                console.log("Settings outgoing", settings)

            req.push({
                request: "configuration",
                method: "set",
                params: [{
                    clearusers: false,
                    factoryreset: false,
                    users: [],
                    settings: btoa(JSON.stringify(settings))
                }]
            })

            this.props.processMessage(req)
            .then(() => message.success("Configuration successfully applied."))
            .catch(error => {
                console.error(error)
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.")
            })
            .finally(() => 
                this.setState({
                    // publicCertificate: null,
                    // privateCertificate: null,
                    // certificatesChanged: false,
                    isLoaded: true 
                })
            )
        })
    }

    changePrivateCertificates = info => {

        if (info.file.status === "done") {
            var elem = {};

            // var elem = info.fileList[info.fileList.length-1]; // prepared for multiple certificates
            elem["type"] = "private";
            elem["content"] = info.file.response;

            this.props.processMessage([{
                request: "certificates",
                method: "set",
                params: [elem]
            }])
            .then(() => this.getCertificates())
            .catch(error => {
                console.error(error);
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.");
            })
        }

        // this.setState({ 
        //     httpsCertificates: info.fileList, // prepared for multiple certificates
        //     certificatesChanged: true
        // })
    }

    changePublicCertificates = info => {

        if (info.file.status === "done") {
            var elem = {};

            // var elem = info.fileList[info.fileList.length-1]; // prepared for multiple certificates
            elem["filename"] = info.file.name;
            elem["type"] = "public";
            elem["content"] = info.file.response;

            this.props.processMessage([{
                request: "certificates",
                method: "set",
                params: [elem]
            }])
            .then(() => this.getCertificates())
            .catch(error => {
                console.error(error);
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.");
            })
        }

        // this.setState({ 
        //     httpsCertificates: info.fileList, // prepared for multiple certificates
        //     certificatesChanged: true
        // })
    }

    // changeDeleteCertificate = value => this.setState({ deleteCertificate: value.target.value });

    changeVpnCertificate = info => {
        if (info.file.status === "done") {
            this.setState({ 
                vpnCertificateName: info.file.name,
                vpnCertificate: info.file.response.toString()
            });

	        message.info("VPN certificate-file " + info.file.name + " read - confirm to apply!", 6);
        }
    }
   
    changeRadiusCertificate = info => {
        if (info.file.status === "done") {
            this.setState({ 
                // wlanRadiusCertificateName: info.file.name,
                wlanRadiusCertificate: info.file.response.toString()
            })
        }
    }

    importCertificate = ({ onSuccess, onError, file }) => {

        this.setState({ 
            isLoaded: false, 
            isWlanModalLoaded: false 
        }, () => {

            const reader = new FileReader();

            reader.onloadend = event => {
                if (reader.readyState === 2) {
                    message.success(`${file.name} uploaded successfully.`);
                    message.info("A reboot of the device is necessary for the changes to take effect!", 6)
                    onSuccess(event.target.result);
                } else {
                    message.error(`${file.name} upload failed.`);
                    onError();
                }
                this.setState({ 
                    isLoaded: true,
                    isWlanModalLoaded: true
                });
            };

            reader.readAsText(file);
        });
    };

    deleteCertificate = (serial) => {

        if (serial) {
            this.setState({ isLoaded: false }, () => {
                this.props.processMessage([{
                    request: "certificates",
                    method: "del",
                    params: [{
                        type: "certserial", // type: "public" // old
                        filename: serial
                    }]
                }])
                .then(() => {
                    message.info("A reboot of the device is necessary for the changes to take effect!", 6)
                    this.getCertificates()
                })
                .catch(error => {
                    console.error(error);
                    message.error(error 
                        ? typeof error.msg === "undefined" 
                            ? error.toString() 
                            : typeof error.msg === "object" 
                                ? error.msg.message 
                                : error.msg
                        : "Unknown Error. Please try again.");
                })
                .finally(() => this.setState({ isLoaded: true }))
            });
        } else {
            message.error("Certificate not found!");
        }
    }

    addWlanNetwork = () => {
        this.setState({ 
            isWlanModalVisible: true,
            wlanName: null,
            wlanPassword: null,
            wlanHidden: false,
            wlanRadiusServer: null,
            wlanRadiusUsername: null,
            wlanRadiusPassword: null,
            wlanRadiusCertificate: null
        })
    }

    deleteWlanNetwork = ssid => {
        const wlanNetworks = [...this.state.wlanNetworks];
        this.setState({ wlanNetworks: wlanNetworks.filter(elem => elem.ssid !== ssid) });
    }

    confirmWlanNetwork = () => { 

        this.setState({ isWlanModalLoaded: false }, () => {

            if (this.state.wlanName) { // TODO: reicht das? was ist verpflichtend für RADIUS?
                
                var alreadyIncluded = false;
                const wlanNetworks = [...this.state.wlanNetworks];
                const { wlanName, wlanPassword, wlanHidden, wlanRadiusServer, wlanRadiusUsername, wlanRadiusPassword, wlanRadiusCertificate } = this.state;

                wlanNetworks.forEach(elem => elem.ssid === wlanName ? alreadyIncluded = true : null); // check if SSID already exist

                if (!alreadyIncluded) {

                    var elem = {
                        ssid: wlanName,
                        password: wlanPassword ? wlanPassword : "",
                        hidden: wlanHidden // always set
                    }
                    
                    if (wlanRadiusServer) {

                        elem["802.1x"] = {
                            radiusserver: wlanRadiusServer,
                            radiususer: wlanRadiusUsername ? wlanRadiusUsername : "",
                            radiuspassword: wlanRadiusPassword ? wlanRadiusPassword : "",
                            radiuscertificate: wlanRadiusCertificate ? wlanRadiusCertificate : ""
                        }
                    }

                    wlanNetworks.push(elem);

                    this.setState({ 
                        wlanNetworks,
                        isWlanModalVisible: false,
                        modalError: null
                    });

                } else {
                    this.setState({ modalError: "An SSID named " + wlanName + " already exists!" })
                }
            } else {
                this.setState({ modalError: "Please enter at least a SSID!" })
            }

            this.setState({ isWlanModalLoaded: true });
        })
    }

    addTimeServer = () => 
        this.setState({ 
            isTimeModalVisible: true,
            timeServerName: null,
        })

    deleteTimeServer = name => {
        const timeServers = [...this.state.timeServers];
        this.setState({ timeServers: timeServers.filter(elem => elem.name !== name) });
    }

    confirmTimeServer = () => { 

        this.setState({ isTimeModalLoaded: false }, () => {

            if (this.state.timeServerName) {
                
                var alreadyIncluded = false;
                const timeServers = [...this.state.timeServers];
                const { timeServerName } = this.state;

                timeServers.forEach(elem => elem.name === timeServerName ? alreadyIncluded = true : null);

                if (!alreadyIncluded) {

                    timeServers.push({ name: timeServerName });

                    this.setState({ 
                        timeServers,
                        isTimeModalVisible: false,
                        modalError: null
                    });

                } else {
                    this.setState({ modalError: "A server named " + timeServerName + " already exists!" })
                }
            } else {
                this.setState({ modalError: "Please enter a server!" })
            }

            this.setState({ isTimeModalLoaded: true });
        })
    }

    addHeitelServer = () => 
        this.setState({ 
            isHeitelModalVisible: true,
            heitelServerName: null,
        })

    deleteHeitelServer = name => {
        const heitelServers = [...this.state.heitelServers];
        this.setState({ heitelServers: heitelServers.filter(elem => elem.name !== name) });
    }

    confirmHeitelServer = () => { 

        this.setState({ isHeitelModalLoaded: false }, () => {

            if (this.state.heitelServerName) {
                
                var alreadyIncluded = false;
                const heitelServers = [...this.state.heitelServers];
                const { heitelServerName } = this.state;

                heitelServers.forEach(elem => elem.name === heitelServerName ? alreadyIncluded = true : null);

                if (!alreadyIncluded) {

                    heitelServers.push({ name: heitelServerName });

                    this.setState({ 
                        heitelServers,
                        isHeitelModalVisible: false,
                        modalError: null
                    });

                } else {
                    this.setState({ modalError: "A server named " + heitelServerName + " already exists!" })
                }
            } else {
                this.setState({ modalError: "Please enter a server!" })
            }

            this.setState({ isHeitelModalLoaded: true });
        })
    }

    addRule = (isOutbound = false, elem = null) => {

        const hasExistingElem = elem?.ident && elem?.direction === (isOutbound ? "out" : "in")

        this.setState({ 
            isPortForwardingModalVisible: !isOutbound, 
            isOutboundModalVisible: isOutbound 
        });
    
        if (hasExistingElem) {
            this.setState({ 
                isRuleModalEdited: true,
                portForwardingState: elem.enable,
                portForwardingName: elem.ident,
            })
    
            if (!isOutbound) {
                this.setState({
                    portForwardingPublicPort: elem.srcport,
                    portForwardingAddress: elem.dstaddr,
                    portForwardingPrivatePort: elem.dstport
                })
            } else {
                this.setState({
                    outboundRuleAddress: elem.srcaddr
                })
            }
        }
    }

    confirmRule = (isOutbound = false) => { 

        this.setState({ isRuleModalLoaded: false }, () => {

            const { 
                portForwardings, 
                portForwardingState, 
                portForwardingName, 
                portForwardingPublicPort, 
                portForwardingAddress, 
                portForwardingPrivatePort,
                outboundRuleAddress
            } = this.state

            const data = [...portForwardings]
            const existingIndex = data.findIndex(elem => elem.direction === (isOutbound ? "out" : "in") && elem.ident === portForwardingName)
            const newElem = {
                enable: portForwardingState,
                ident: portForwardingName,
                srcport: isOutbound ? 0 : portForwardingPublicPort,
                dstaddr: isOutbound ? "" : portForwardingAddress,
                dstport: isOutbound ? 0 : portForwardingPrivatePort,
                srcaddr: isOutbound ? outboundRuleAddress : "",
                proto: "",
                direction: isOutbound ? "out" : "in"
            }

            if (existingIndex === -1) {
                data.push(newElem)
            } else {
                data[existingIndex] = newElem
            }
                
            this.setState({
                portForwardings: data,
                isRuleModalLoaded: true
            }, () => this.closeRuleModal())
        })
    }

    deleteRule = ident => {
        const portForwardings = [...this.state.portForwardings];
        this.setState({ portForwardings: portForwardings.filter(elem => elem.ident !== ident) });
    }

    closeRuleModal = () => {
        this.setState({
            isRuleModalEdited: false,
            portForwardingState: true,
            portForwardingName: null,
            portForwardingPublicPort: null,
            portForwardingAddress: null,
            portForwardingPrivatePort: null,
            outboundRuleAddress: null
        })
        this.cancelModal()
    }

    cancelModal = () => {
        this.setState({ 
            isWlanModalVisible: false, 
            isTimeModalVisible: false, 
            isHeitelModalVisible: false,
            isPortForwardingModalVisible: false,
            isOutboundModalVisible: false,
            modalError: null 
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form

        const {
            azureAccount,
            azureKey,
            certificates,
            // deleteCertificate, 
            dhcpState,
            dhcpRangeStart,
            dhcpRangeEnd,
            isLoaded, 
            isWlanModalLoaded,
            isWlanModalVisible,
            isTimeModalLoaded,
            isTimeModalVisible,
            isHeitelModalVisible,
            isHeitelModalLoaded,
            isOutboundModalVisible,
            isPortForwardingModalVisible,
            isRuleModalLoaded,
            isRuleModalEdited,
            heitelPort,
            heitelPassword,
            heitelState,
            heitelServers,
            heitelServerName,
            lanState, 
            lanV4Mode,
            lanV4Address,
            lanV4Gateway,
            lanV4Dns,
            mediaRetainment,
            mediaRetainmentUnit,
            mobileState,
            mobileSim,
            modalError,
            mobileApn,
            mobilePin,
            mobileUsername,
            mobilePassword,
            mobilePing,
            mobileApn2,
            mobilePin2,
            mobileUsername2,
            mobilePassword2,
            mobilePing2,
            notificationRetainment,
            notificationRetainmentUnit,
            nvrName,
            oidServer,
            onvifPassword,
            outboundRuleAddress,
            portForwardings,
            portForwardingState,
            portForwardingName,
            portForwardingPublicPort,
            portForwardingAddress,
            portForwardingPrivatePort,
            smtpEnc,
            smtpServer,
            smtpUsername,
            smtpPassword,
            smtpSender,
            smtpEnc2,
            smtpServer2,
            smtpUsername2,
            smtpPassword2,
            smtpSender2,
            smtpEnc3,
            smtpServer3,
            smtpUsername3,
            smtpPassword3,
            smtpSender3,
            timeServers,
            timeServerName,
            vcaServer,
            vpnState, 
            vpnClientMode,
            vpnServer,
            vpnClientAddress,
            vpnUsername,
            vpnPassword,
            vpnPingDst,
            webrtcStunServer,
            webrtcServer,
            webrtcTurnServer,
            webrtcTurnUsername,
            webrtcTurnPassword,
            wlanRadiusServer,
            wlanName,
            wlanState,
            wlanHidden,
            wlanNetworks,
            wlanMode,
            wlanAccesspointPassword,
        } = this.state

        const confirmPortForwardingDisabled = !portForwardingName || !portForwardingPublicPort || !portForwardingAddress || !portForwardingPrivatePort
        const confirmOutboundRuleDisabled = !portForwardingName || !outboundRuleAddress
        const confirmWlanNetworkDisabled = !wlanName
        const confirmTimeServerDisabled = !timeServerName
        const confirmHeitelServerDisabled = !heitelServerName
        const certificatesTrustedAvailable = certificates?.trusted.length
        const certificatesVpnAvailable = certificates?.vpn.length
        const isAdmin = this.props.access && this.props.access === "administrator"

        return (
            <Form 
                labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} 
                wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }}
            >
                    
                <Collapse>

                    <Collapse.Panel header="Analytics" key="1" disabled={!isLoaded}>
                        <Form.Item label="VCA License Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vcaServer', {
                                onChange: this.changeVcaServer,
                                initialValue: vcaServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="none" disabled={!isLoaded} />
                            )}
                        </Form.Item>
                    </Collapse.Panel>

                    <Collapse.Panel header="Cloud" key="2" disabled={!isLoaded}>

                        <Divider>Client authentication</Divider>

                        <Form.Item label="Azure Storage Account" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('azureAccount', {
                                onChange: this.changeAzureAccount,
                                initialValue: azureAccount,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input
                                    placeholder="none"
                                    disabled={!isLoaded} 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Azure Account Key" style={{ marginBottom: 5 }}>
                            {getFieldDecorator('azureKey', {
                                onChange: this.changeAzureKey,
                                initialValue: azureKey,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password
                                    placeholder={azureAccount ? "Please enter a key" : "none"}
                                    disabled={!isLoaded || !azureAccount} 
                                />
                            )}
                        </Form.Item>

                        <Divider>Server validation</Divider>

                        <Form.Item label="OID Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('oidServer', {
                                onChange: this.changeOidServer,
                                initialValue: oidServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input
                                    placeholder="none"
                                    disabled={!isLoaded || !isAdmin}
                                />
                            )}
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="Date &amp; Time" key="3" disabled={!isLoaded}>

                        <Form.Item label="NTP" style={{ marginBottom: 0 }}>
                            <Table 
                                size="small"
                                bordered
                                dataSource={timeServers} 
                                rowKey={record => record.name} 
                                columns={[
                                    {
                                        title: 'Server',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: () => <>
                                            Controls
                                            <span style={{ float: "right" }}>
                                                <Button type="primary" size="small" onClick={this.addTimeServer} disabled={!isLoaded}>
                                                    <Icon type="plus" />Add
                                                </Button>
                                            </span>
                                        </>,
                                        render: elem => <>
                                            <Popconfirm 
                                                disabled={!isLoaded}
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteTimeServer(elem.name)}
                                            >
                                                <Button type="link" disabled={!isLoaded}>
                                                    <Icon type="delete" />Delete
                                                </Button>
                                            </Popconfirm>
                                        </>
                                    },
                                ]} 
                            />
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="Ethernet" key="4" disabled={!isLoaded}>

                        <Form.Item label="State" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('lanState', {
                                onChange: this.changeLanState,
                                initialValue: lanState
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="IPv4 Mode" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('lanV4Mode', {
                                onChange: this.changelanV4Mode,
                                initialValue: lanV4Mode
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded || !lanState}>
                                <Radio.Button value={"dhcp"} style={{ width: "50%", textAlign: "center" }}>DHCP</Radio.Button>
                                <Radio.Button value={"static"} style={{ width: "50%", textAlign: "center" }}>Static</Radio.Button>
                            </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="IPv4 Address" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('lanV4Address', {
                                onChange: this.changeLanV4Address,
                                initialValue: lanV4Address,
                                rules: [{ pattern: REGEX.IPV4MASK, message: REGEX.IPV4MASK_MSG }]
                            })(
                                <Input placeholder="192.168.20.20/24" disabled={!isLoaded || !lanState || (lanV4Mode === "dhcp")} />
                            )}
                        </Form.Item>

                        <Form.Item label="IPv4 Gateway" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('lanV4Gateway', {
                                onChange: this.changeLanV4Gateway,
                                initialValue: lanV4Gateway,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="192.168.20.1" disabled={!isLoaded || !lanState || (lanV4Mode === "dhcp")} />
                            )}
                        </Form.Item>

                        <Form.Item label="IPv4 DNS" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('lanV4Dns', {
                                onChange: this.changeLanV4Dns,
                                initialValue: lanV4Dns,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="192.168.20.1" disabled={!isLoaded || !lanState || (lanV4Mode === "dhcp")} />
                            )}
                        </Form.Item>

                        <Divider>DHCP-Service</Divider>

                        <Form.Item label="State" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('dhcpState', {
                                onChange: this.changeDhcpState,
                                initialValue: dhcpState
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded || !lanState || (lanV4Mode === "dhcp")}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="IP-Range" style={{ marginBottom: 0 }}>

                            <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc((100% / 2) - (16px / 2))' }}>
                                {getFieldDecorator('dhcpRangeStart', {
                                    onChange: this.changeDhcpRangeStart,
                                    initialValue: dhcpRangeStart,
                                    rules: [{ pattern: REGEX.IPV4, message: REGEX.IPV4_MSG }]
                                })(
                                    <Input placeholder="192.168.20.20" disabled={!isLoaded || !lanState || (lanV4Mode === "dhcp") || !dhcpState} />
                                )}
                            </Form.Item>

                            <span style={{ display: 'inline-block', width: '16px', textAlign: "center" }}>-</span>

                            <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc((100% / 2) - (16px / 2))' }}>                            
                                {getFieldDecorator('dhcpRangeEnd', {
                                    onChange: this.changeDhcpRangeEnd,
                                    initialValue: dhcpRangeEnd,
                                    rules: [{ pattern: REGEX.IPV4, message: REGEX.IPV4_MSG }]
                                })(
                                    <Input placeholder="192.168.20.20" disabled={!isLoaded || !lanState || (lanV4Mode === "dhcp") || !dhcpState} />
                                )}
                            </Form.Item>

                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="General" key="5" disabled={!isLoaded}>

                        <Form.Item label="NVR Name" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('nvrName', {
                                onChange: this.changeNvrName,
                                initialValue: nvrName,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input
                                    placeholder="Enter a name or leave blank to use hostname"
                                    disabled={!isLoaded} 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Media Retainment" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mediaRetainment', {
                                rules: [{ pattern: NUMBER, message: NUMBER_MSG }],
                                onChange: this.changeMediaRetainment,
                                initialValue: mediaRetainment
                            })(
                                <Input 
                                    placeholder="Please enter a duration" 
                                    style={{ width: "100%" }} 
                                    disabled={!isLoaded}
                                    addonAfter={(
                                        <Select 
                                            onChange={this.changeMediaRetainmentUnit}
                                            value={mediaRetainmentUnit}
                                            style={{ width: "100px" }}
                                        >
                                            <Select.Option value="hours">hours</Select.Option>
                                            <Select.Option value="days">days</Select.Option>
                                            <Select.Option value="weeks">weeks</Select.Option>
                                            <Select.Option value="years">years</Select.Option>
                                        </Select>
                                    )}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Notification Retainment" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('notificationRetainment', {
                                rules: [{ pattern: NUMBER, message: NUMBER_MSG }],
                                onChange: this.changeNotificationRetainment,
                                initialValue: notificationRetainment
                            })(
                                <Input 
                                    placeholder="Please enter a duration" 
                                    style={{ width: "100%" }} 
                                    disabled={!isLoaded}
                                    addonAfter={(
                                        <Select 
                                            onChange={this.changeNotificationRetainmentUnit}
                                            value={notificationRetainmentUnit}
                                            style={{ width: "100px" }}
                                        >
                                            <Select.Option value="hours">hours</Select.Option>
                                            <Select.Option value="days">days</Select.Option>
                                            <Select.Option value="weeks">weeks</Select.Option>
                                            <Select.Option value="years">years</Select.Option>
                                        </Select>
                                    )}
                                />
                            )}
                        </Form.Item>

                    </Collapse.Panel> 

                    <Collapse.Panel header="HeiTel" key="6" disabled={!isLoaded}>
                        
                        <Form.Item label="State" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('heitelState', {
                                onChange: this.changeHeitelState,
                                initialValue: heitelState
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="Port" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('heitelPort', {
                                onChange: this.changeHeitelPort,
                                initialValue: heitelPort
                            })(
                                <InputNumber placeholder="3000" style={{ width: "100%" }} min={1} max={65535} disabled={!isLoaded || !heitelState} />
                            )}
                        </Form.Item>

                        <Form.Item label="Password" style={{ marginBottom: 5 }}>
                            {getFieldDecorator('heitelPassword', {
                                onChange: this.changeHeitelPassword,
                                initialValue: heitelPassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="Please enter a password" disabled={!isLoaded || !heitelState} />
                            )}
                        </Form.Item>

                        <Form.Item label="Alarm Targets" style={{ marginBottom: 0 }}>
                            <Table 
                                size="small"
                                bordered
                                dataSource={heitelServers} 
                                rowKey={record => record.name} 
                                columns={[
                                    {
                                        title: 'Server',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        // width: 150,
                                        title: () => <>
                                            Controls
                                            <span style={{ float: "right" }}>
                                                <Button type="primary" size="small" onClick={this.addHeitelServer} disabled={!isLoaded || !heitelState}>
                                                    <Icon type="plus" />Add
                                                </Button>
                                            </span>
                                        </>,
                                        render: elem => <>
                                            <Popconfirm 
                                                disabled={!isLoaded || !heitelState}
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteHeitelServer(elem.name)}
                                            >
                                                <Button type="link" disabled={!isLoaded || !heitelState}>
                                                    <Icon type="delete" />Delete
                                                </Button>
                                            </Popconfirm>
                                        </>
                                    },
                                ]} 
                            />
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="Mail" key="7" disabled={!isLoaded}>

                        <Form.Item label="Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('smtpServer', {
                                onChange: this.changeSmtpServer,
                                initialValue: smtpServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="Please enter a server address" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Username" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('smtpUsername', {
                                onChange: this.changeSmtpUsername,
                                initialValue: smtpUsername,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="anonymous" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('smtpPassword', {
                                onChange: this.changeSmtpPassword,
                                initialValue: smtpPassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="anonymous" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Sender" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('smtpSender', {
                                onChange: this.changeSmtpSender,
                                initialValue: smtpSender,
                                rules: [
                                    { type: "email", message: "Please enter a valid Email!" },
                                    { pattern: NAME, message: NAME_MSG }
                                ]
                            })(
                                <Input placeholder="Please enter a sender address" disabled={!isLoaded} />              
                            )}
                        </Form.Item>

                        <Form.Item label="SSL/TLS" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('smtpEnc', {
                                onChange: this.changeSmtpEnc,
                                initialValue: smtpEnc
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Divider>Sentinel</Divider>

                            <Form.Item label="Server" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpServer2', {
                                    onChange: this.changeSmtpServer2,
                                    initialValue: smtpServer2,
                                    rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                })(
                                    <Input placeholder="Please enter a server address" disabled={!isLoaded} />
                                )}
                            </Form.Item>

                            <Form.Item label="Username" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpUsername2', {
                                    onChange: this.changeSmtpUsername2,
                                    initialValue: smtpUsername2,
                                    rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                })(
                                    <Input placeholder="anonymous" disabled={!isLoaded} />
                                )}
                            </Form.Item>

                            <Form.Item label="Password" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpPassword2', {
                                    onChange: this.changeSmtpPassword2,
                                    initialValue: smtpPassword2,
                                    rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                })(
                                    <Input.Password placeholder="anonymous" disabled={!isLoaded} />
                                )}
                            </Form.Item>

                            <Form.Item label="Sender" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpSender2', {
                                    onChange: this.changeSmtpSender2,
                                    initialValue: smtpSender2,
                                    rules: [
                                        { type: "email", message: "Please enter a valid Email!" },
                                        { pattern: NAME, message: NAME_MSG }
                                    ]                                })(
                                    <Input placeholder="Please enter a sender address" disabled={!isLoaded} />              
                                )}
                            </Form.Item>

                            <Form.Item label="SSL/TLS" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpEnc2', {
                                    onChange: this.changeSmtpEnc2,
                                    initialValue: smtpEnc2
                                })(
                                    <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                        <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                        <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                        <Divider>SureView</Divider>

                            <Form.Item label="Server" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpServer3', {
                                    onChange: this.changeSmtpServer3,
                                    initialValue: smtpServer3,
                                    rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                })(
                                    <Input placeholder="Please enter a server address" disabled={!isLoaded} />
                                )}
                            </Form.Item>

                            <Form.Item label="Username" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpUsername3', {
                                    onChange: this.changeSmtpUsername3,
                                    initialValue: smtpUsername3,
                                    rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                })(
                                    <Input placeholder="anonymous" disabled={!isLoaded} />
                                )}
                            </Form.Item>

                            <Form.Item label="Password" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpPassword3', {
                                    onChange: this.changeSmtpPassword3,
                                    initialValue: smtpPassword3,
                                    rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                })(
                                    <Input.Password placeholder="anonymous" disabled={!isLoaded} />
                                )}
                            </Form.Item>

                            <Form.Item label="Sender" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpSender3', {
                                    onChange: this.changeSmtpSender3,
                                    initialValue: smtpSender3,
                                    rules: [
                                        { type: "email", message: "Please enter a valid Email!" },
                                        { pattern: NAME, message: NAME_MSG }
                                    ]                                })(
                                    <Input placeholder="Please enter a sender address" disabled={!isLoaded} />              
                                )}
                            </Form.Item>

                            <Form.Item label="SSL/TLS" style={{ marginBottom: 0 }}>
                                {getFieldDecorator('smtpEnc3', {
                                    onChange: this.changeSmtpEnc3,
                                    initialValue: smtpEnc3
                                })(
                                    <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                        <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                        <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                                    
                    </Collapse.Panel>

                    <Collapse.Panel header="Mobile" key="8" disabled={!isLoaded}>

                        <Form.Item label="State" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobileState', {
                                onChange: this.changeMobileState,
                                initialValue: mobileState
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="SIM" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobileSim', {
                                onChange: this.changeMobileSim,
                                initialValue: mobileSim
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={0} style={{ width: "50%", textAlign: "center" }}>1</Radio.Button>
                                    <Radio.Button value={1} style={{ width: "50%", textAlign: "center" }}>2</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Divider>SIM 1</Divider>

                        <Form.Item label="APN" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobileApn', {
                                onChange: this.changeMobileApn,
                                initialValue: mobileApn,
                                rules: [{ pattern: REGEX.HOSTNAME, message: REGEX.HOSTNAME_MSG }]
                            })(
                                <Input placeholder="none" disabled={!mobileState || Boolean(mobileSim) || !isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="PIN" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobilePin', {
                                onChange: this.changeMobilePin,
                                initialValue: mobilePin
                            })(
                                <InputNumber 
                                    placeholder="none" 
                                    style={{ width: "100%" }} 
                                    max={99999999} 
                                    disabled={!mobileState || Boolean(mobileSim) || !isLoaded}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Username" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobileUsername', {
                                onChange: this.changeMobileUsername,
                                initialValue: mobileUsername,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input 
                                    placeholder="anonymous" 
                                    disabled={!mobileState || Boolean(mobileSim) || !isLoaded} 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobilePassword', {
                                onChange: this.changeMobilePassword,
                                initialValue: mobilePassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="anonymous" disabled={!mobileState || Boolean(mobileSim) || !isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Ping Host" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobilePing', {
                                onChange: this.changeMobilePing,
                                initialValue: mobilePing,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input 
                                    placeholder="none" 
                                    disabled={!mobileState || Boolean(mobileSim) || !isLoaded} 
                                />
                            )}
                        </Form.Item>

                        <Divider>SIM 2</Divider>

                        <Form.Item label="APN" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobileApn2', {
                                onChange: this.changeMobileApn2,
                                initialValue: mobileApn2,
                                rules: [{ pattern: REGEX.HOSTNAME, message: REGEX.HOSTNAME_MSG }]
                            })(
                                <Input placeholder="none" disabled={!mobileState || !Boolean(mobileSim) || !isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="PIN" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobilePin2', {
                                onChange: this.changeMobilePin2,
                                initialValue: mobilePin2
                            })(
                                <InputNumber placeholder="none" style={{ width: "100%" }} max={99999999} disabled={!mobileState || !Boolean(mobileSim) || !isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Username" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobileUsername2', {
                                onChange: this.changeMobileUsername2,
                                initialValue: mobileUsername2,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="anonymous" disabled={!mobileState || !Boolean(mobileSim) || !isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobilePassword2', {
                                onChange: this.changeMobilePassword2,
                                initialValue: mobilePassword2,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="anonymous" disabled={!mobileState || !Boolean(mobileSim) || !isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="Ping Host" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('mobilePing2', {
                                onChange: this.changeMobilePing2,
                                initialValue: mobilePing2,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input 
                                    placeholder="none" 
                                    disabled={!mobileState || !Boolean(mobileSim) || !isLoaded} 
                                />
                            )}
                        </Form.Item>
                                    
                    </Collapse.Panel>

                    <Collapse.Panel header="Network Rules" key="9" disabled={!isLoaded}>

                        <Form.Item label="Port Forwarding (Inbound)" style={{ marginBottom: 5 }}>
                            <Table 
                                size="small"
                                bordered
                                dataSource={portForwardings.filter(elem => !elem.srcaddr)} 
                                rowKey={record => record.ident} 
                                columns={[
                                    {
                                        title: 'State',
                                        dataIndex: 'enable',
                                        render: (elem) => elem  ? "Active" : "Inactive"
                                    },
                                    {
                                        title: 'Description',
                                        dataIndex: 'ident',
                                        key: 'ident',
                                    },
                                    {
                                        title: 'Source Port',
                                        dataIndex: 'srcport',
                                        key: 'srcport',
                                    },
                                    {
                                        title: 'Destination Address',
                                        dataIndex: 'dstaddr',
                                        key: 'dstaddr',
                                    },
                                    {
                                        title: 'Destination Port',
                                        dataIndex: 'dstport',
                                        key: 'dstport',
                                    },
                                    {
                                        title: () => <>
                                            Controls
                                            <span style={{ float: "right" }}>
                                                <Button type="primary" size="small" onClick={() => this.addRule(false)} disabled={!isLoaded}>
                                                    <Icon type="plus" />Add
                                                </Button>
                                            </span>
                                        </>,
                                        render: elem => <>
                                            <Button type="link" onClick={() => this.addRule(false, elem)} disabled={!isLoaded}>
                                                <Icon type="edit" />Edit
                                            </Button>

                                            <Divider type="vertical" />

                                            <Popconfirm 
                                                disabled={!isLoaded}
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteRule(elem.ident)}
                                            >
                                                <Button type="link" disabled={!isLoaded}>
                                                    <Icon type="delete" />Delete
                                                </Button>
                                            </Popconfirm>
                                        </>
                                    },
                                ]} 
                            />
                        </Form.Item>

                        <Form.Item label="Outbound Rules" style={{ marginBottom: 0 }}>
                            <Table 
                                size="small"
                                bordered
                                dataSource={portForwardings.filter(elem => elem.srcaddr)} 
                                rowKey={record => record.ident} 
                                columns={[
                                    {
                                        title: 'State',
                                        dataIndex: 'enable',
                                        render: (elem) => elem  ? "Active" : "Inactive"
                                    },
                                    {
                                        title: 'Description',
                                        dataIndex: 'ident',
                                        key: 'ident',
                                    },
                                    {
                                        title: 'Source Address',
                                        dataIndex: 'srcaddr',
                                        key: 'srcaddr',
                                    },
                                    {
                                        title: () => <>
                                            Controls
                                            <span style={{ float: "right" }}>
                                                <Button type="primary" size="small" onClick={() => this.addRule(true)} disabled={!isLoaded}>
                                                    <Icon type="plus" />Add
                                                </Button>
                                            </span>
                                        </>,
                                        render: elem => <>
                                            <Button type="link" onClick={() => this.addRule(true, elem)} disabled={!isLoaded}>
                                                <Icon type="edit" />Edit
                                            </Button>

                                            <Divider type="vertical" />

                                            <Popconfirm 
                                                disabled={!isLoaded}
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteRule(elem.ident)}
                                            >
                                                <Button type="link" disabled={!isLoaded}>
                                                    <Icon type="delete" />Delete
                                                </Button>
                                            </Popconfirm>
                                        </>
                                    },
                                ]} 
                            />
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="ONVIF" key="10" disabled={!isLoaded}>

                        <Form.Item label="Username" style={{ marginBottom: 0 }}>
                            <Input defaultValue="onvifadmin" disabled />
                        </Form.Item>

                        <Form.Item label="Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('onvifPassword', {
                                onChange: this.changeOnvifPassword,
                                initialValue: onvifPassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="Please enter a password" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="Security" key="11" disabled={!isLoaded}>

                        <Form.Item label="Import Certificate" style={{ marginBottom: 0 }}>

                            {/* <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc((100% / 3) - (12px / 3))' }}>
                                
                                <Button style={{ width: "100%" }} disabled={!isLoaded || unused}>
                                    <Icon type="form" /> Create Self-signed
                                </Button>

                            </Form.Item>

                            <span style={{ display: 'inline-block', width: '6px'}}></span> 

                            <span style={{ display: "flex", gap: "6px", alignItems: "center" }}>

                                <Form.Item style={{ marginBottom: 0, width: "100%", height: "100%" }}>
                                    
                                    <Upload
                                        accept=".pem"
                                        showUploadList={false}
                                        customRequest={this.importCertificate}
                                        onChange={this.changePrivateCertificates}
                                    >
                                        <Button className="responsiveButton" disabled={!isLoaded}>
                                            <Icon type="upload" />New NVR certificate
                                        </Button>
                                    </Upload>

                                </Form.Item> */}

                                <Form.Item style={{ marginBottom: 0, width: "100%", height: "100%" }}>
                                    
                                    <Upload
                                        accept=".pem"
                                        showUploadList={false}
                                        customRequest={this.importCertificate}
                                        onChange={this.changePublicCertificates}
                                    >
                                        <Button className="responsiveButton" disabled={!isLoaded}>
                                            <Icon type="upload" />Add trusted certificate
                                        </Button>
                                    </Upload>

                                </Form.Item>

                           {/* </span>

                             prepared for multiple certificates
                            <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc(33% - 12px)' }}>
                                
                                <Upload
                                    accept=".pem"
                                    fileList={httpsCertificates} // 
                                    customRequest={this.importCertificate}
                                    onChange={this.changeHttpsCertificates}
                                >
                                    <Button style={{ width: "100%" }} disabled={!isLoaded}>
                                        <Icon type="upload" /> Import Existing
                                    </Button>
                                </Upload>

                            </Form.Item> */}

                        </Form.Item>

                        {/* <Form.Item label="Delete Certificate" style={{ marginBottom: 0 }}>

                            <span style={{ display: "flex", gap: "6px" }}>

                                <Form.Item style={{ marginBottom: 0, width: '100%' }}>
                                    {getFieldDecorator('deleteCertificate', {
                                        onChange: this.changeDeleteCertificate,
                                        rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                                    })(
                                        <Input placeholder="Enter a filename or leave blank to delete all certficates" disabled={!isLoaded} />
                                    )}
                                </Form.Item>
                                    
                                <Form.Item style={{ marginBottom: 0 }}>

                                    {deleteCertificate ? (
                                        <Button icon="delete" disabled={!isLoaded} onClick={this.deleteCertificate} />
                                    ) : (
                                        <Popconfirm 
                                            title="Are you sure?"
                                            okText='Yes'
                                            okType='danger'
                                            cancelText='No'
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={this.deleteCertificate}
                                        >
                                            <Button icon="delete" disabled={!isLoaded} />
                                        </Popconfirm>
                                    )}

                                </Form.Item>

                            </span>

                        </Form.Item> */}

                        <Form.Item label="Available Certificates" style={{ marginBottom: 0 }}>

                            <List
                                split={false}
                                rowKey={item => item.serial}
                                size="small"
                                dataSource={certificatesTrustedAvailable ? certificates.trusted : [{ cn: "-" }]}
                                loading={!isLoaded}
                                renderItem={item => (
                                    <List.Item 
                                        style={{ paddingBottom: 0 }}
                                        actions={certificatesTrustedAvailable ? [
                                            <Popconfirm 
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteCertificate(item.serial)}
                                            >
                                                <Button 
                                                    icon="delete" 
                                                    size="small" 
                                                    disabled={!isLoaded}
                                                />
                                            </Popconfirm>
                                        ] : []}
                                    >
                                        {item.cn} {item.validnotafter && `(valid until ${unixToFormatted(item.validnotafter)})`}
                                    </List.Item>
                                )}
                            />
                            
                        </Form.Item>

                        {/* {certificatesChanged ? <div style={{ color: "red", textAlign: "center", lineHeight: "40px", marginBottom: 0 }}>A reboot of the device is necessary for the changes to take effect!</div> : null} */}

                    </Collapse.Panel>

                    <Collapse.Panel header="VPN" key="12" disabled={!isLoaded}>

                        <Form.Item label="State" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnState', {
                                onChange: this.changeVpnState,
                                initialValue: vpnState
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnServer', {
                                onChange: this.changeVpnServer,
                                initialValue: vpnServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="Please enter a server address" disabled={!isLoaded || !vpnState} />
                            )}
                        </Form.Item>

                        <Form.Item label="Username" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnUsername', {
                                onChange: this.changeVpnUsername,
                                initialValue: vpnUsername,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })( 
                                <Input placeholder="anonymous" disabled={!isLoaded || !vpnState} />
                            )}
                        </Form.Item>

                        <Form.Item label="Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnPassword', {
                                onChange: this.changeVpnPassword,
                                initialValue: vpnPassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="anonymous" disabled={!isLoaded || !vpnState} />
                            )}
                        </Form.Item>

                        <Form.Item label="Mode" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnClientMode', {
                                onChange: this.changeVpnClientMode,
                                initialValue: vpnClientMode
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded || !vpnState}>
                                    <Radio.Button value={"dhcp"} style={{ width: "50%", textAlign: "center" }}>DHCP</Radio.Button>
                                    <Radio.Button value={"static"} style={{ width: "50%", textAlign: "center" }}>Static</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="Address" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnClientAddress', {
                                onChange: this.changeVpnClientAddress,
                                initialValue: vpnClientAddress,
                                rules: [{ pattern: REGEX.IPV4MASK, message: REGEX.IPV4MASK_MSG }]
                            })(
                                <Input placeholder="192.168.20.20/24" disabled={!isLoaded || !vpnState || (vpnClientMode === "dhcp")} />
                            )}
                        </Form.Item>

                        <Form.Item label="Ping Host" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('vpnPingDst', {
                                onChange: this.changeVpnPingDst,
                                initialValue: vpnPingDst,
                                rules: [{ pattern: REGEX.IPV4, message: REGEX.IPV4_MSG }]
                            })(
                                <Input 
                                    placeholder="none" 
                                    disabled={!isLoaded || !vpnState} 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Import Certificate" style={{ marginBottom: 0 }}>
                            <Upload
                                accept=".pem"
                                showUploadList={false}
                                customRequest={this.importCertificate}
                                onChange={this.changeVpnCertificate}
                            >
                                <Button style={{ width: "100%" }} disabled={!isLoaded || !vpnState}>
                                    <Icon type="upload" /> Select
                                </Button>
                            </Upload>
                        </Form.Item>
                                
                        <Form.Item label="Available Certificates" style={{ marginBottom: 0 }}>
                            
                            <List
                                split={false}
                                rowKey={item => item.serial}
                                size="small"
                                dataSource={certificatesVpnAvailable ? certificates.vpn : [{ cn: "-" }]}
                                loading={!isLoaded}
                                renderItem={item => (
                                    <List.Item 
                                        style={{ paddingBottom: 0 }}
                                        actions={certificatesVpnAvailable ? [
                                            <Popconfirm 
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteCertificate(item.serial)}
                                            >
                                                <Button 
                                                    icon="delete" 
                                                    size="small" 
                                                    disabled={!isLoaded}
                                                />
                                            </Popconfirm>
                                        ] : []}
                                    >
                                        {item.cn} {item.validnotafter && `(valid until ${unixToFormatted(item.validnotafter)})`}
                                    </List.Item>
                                )}
                            />
                            
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="WebRTC" key="13" disabled={!isLoaded}>

                        <Form.Item label="Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('webrtcServer', {
                                onChange: this.changeWebrtcServer,
                                initialValue: webrtcServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="Please enter a server address" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Divider>ICE Servers</Divider>

                        <Form.Item label="STUN Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('webrtcStunServer', {
                                onChange: this.changeWebrtcStunServer,
                                initialValue: webrtcStunServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="Please enter a server URI" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="TURN Server" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('webrtcTurnServer', {
                                onChange: this.changeWebrtcTurnServer,
                                initialValue: webrtcTurnServer,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="Please enter a server URI" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="TURN Username" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('webrtcTurnUsername', {
                                onChange: this.changeWebrtcTurnUsername,
                                initialValue: webrtcTurnUsername,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input placeholder="anonymous" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                        <Form.Item label="TURN Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('webrtcTurnPassword', {
                                onChange: this.changeWebrtcTurnPassword,
                                initialValue: webrtcTurnPassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="anonymous" disabled={!isLoaded} />
                            )}
                        </Form.Item>

                    </Collapse.Panel>

                    <Collapse.Panel header="WiFi" key="14" disabled={!isLoaded}>

                        <Form.Item label="State" style={{ marginBottom: 5 }}>
                            {getFieldDecorator('wlanState', {
                                onChange: this.changeWlanState,
                                initialValue: wlanState
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        
                        <Form.Item label="Mode" style={{ marginBottom: 5 }}>
                            {getFieldDecorator('wlanMode', {
                                onChange: this.changeWlanMode,
                                initialValue: wlanMode
                            })(
                                <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded || !wlanState}>
                                    <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Infrastructure</Radio.Button>
                                    <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Access Point</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>

                        <Form.Item label="Networks" style={{ marginBottom: 5 }}>
                            <Table 
                                size="small"
                                bordered
                                dataSource={wlanNetworks} 
                                rowKey={record => record.ssid} 
                                columns={[
                                    {
                                        title: 'SSID',
                                        dataIndex: 'ssid',
                                        key: 'ssid',
                                    },
                                    {
                                        title: 'Hidden',
                                        dataIndex: 'hidden',
                                        key: 'hidden',
                                        render: elem => elem.toString()
                                    },
                                    {
                                        title: () => <>
                                            Controls
                                            <span style={{ float: "right" }}>
                                                <Button type="primary" size="small" onClick={this.addWlanNetwork} disabled={!isLoaded || !wlanState || !wlanMode}>
                                                    <Icon type="plus" />Add
                                                </Button>
                                            </span>
                                        </>,
                                        render: elem => <>
                                            <Popconfirm 
                                                disabled={!isLoaded || !wlanState || !wlanMode}
                                                title="Are you sure?"
                                                okText='Yes'
                                                okType='danger'
                                                cancelText='No'
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                onConfirm={() => this.deleteWlanNetwork(elem.ssid)}
                                            >
                                                <Button type="link" disabled={!isLoaded || !wlanState || !wlanMode}>
                                                    <Icon type="delete" />Delete
                                                </Button>
                                            </Popconfirm>
                                        </>
                                    },
                                ]} 
                            />
                        </Form.Item>
                                    
                        <Form.Item label="Password" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('wlanAccesspointPassword', {
                                onChange: this.changeWlanAccesspointPassword,
                                initialValue: wlanAccesspointPassword,
                                rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                            })(
                                <Input.Password placeholder="anonymous" disabled={!isLoaded || !wlanState || wlanMode} />
                            )}
                        </Form.Item>

                    </Collapse.Panel>
                
                </Collapse>

                {/* Date & Time */}

                <Modal
                    width="750px"
                    centered
                    closable={false}
                    visible={isTimeModalVisible}
                    onOk={this.confirmTimeServer}
                    onCancel={this.cancelModal}
                    okText="Confirm"
                    confirmLoading={!isTimeModalLoaded}
                    destroyOnClose
                    okButtonProps={{ disabled: confirmTimeServerDisabled }}
                >
                    <Form.Item label="Server" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('timeServerName', {
                            onChange: this.changeTimeServerName,
                            rules: [{ required: true, message: 'Please enter a server name or address!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input placeholder="Please enter a server name or address" disabled={!isTimeModalLoaded} />
                        )}
                    </Form.Item>

                    {modalError ? <div style={{ marginTop: "12px", color: "red", textAlign: "center" }}>{modalError}</div> : null}

                </Modal>

                {/* WiFi */}

                <Modal
                    width="750px"
                    centered
                    closable={false}
                    visible={isWlanModalVisible}
                    onOk={this.confirmWlanNetwork}
                    onCancel={this.cancelModal}
                    okText="Confirm"
                    confirmLoading={!isWlanModalLoaded}
                    destroyOnClose
                    okButtonProps={{ disabled: confirmWlanNetworkDisabled }}
                >
                    <Form.Item label="SSID" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('wlanName', {
                            onChange: this.changeWlanName,
                            rules: [{ required: true, message: 'Please enter a SSID!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input placeholder="Please enter a SSID" disabled={!isWlanModalLoaded} />
                        )}
                    </Form.Item>
                    
                    <Form.Item label="Password" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('wlanPassword', {
                            onChange: this.changeWlanPassword,
                            rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input.Password placeholder="Please enter a password" disabled={!isWlanModalLoaded || !wlanName} />
                        )}
                    </Form.Item>

                    <Form.Item label="Hidden" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('wlanHidden', {
                            onChange: this.changeWlanHidden,
                            initialValue: wlanHidden
                        })(
                            <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isWlanModalLoaded || !wlanName}>
                                <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Divider>RADIUS (optional)</Divider>

                    <Form.Item label="Server" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('wlanRadiusServer', {
                            onChange: this.changeWlanRadiusServer,
                            rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input placeholder="Please enter a RADIUS server name or address" disabled={!isWlanModalLoaded || !wlanName} />
                        )}
                    </Form.Item>

                    <Form.Item label="Username" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('wlanRadiusUsername', {
                            onChange: this.changeWlanRadiusUsername,
                            rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input placeholder="Please enter a RADIUS username" disabled={!isWlanModalLoaded || !wlanRadiusServer || !wlanName} />
                        )}
                    </Form.Item>

                    <Form.Item label="Password" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('wlanRadiusPassword', {
                            onChange: this.changeWlanRadiusPassword,
                            rules: [{ pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input.Password placeholder="Please enter a RADIUS password" disabled={!isWlanModalLoaded || !wlanRadiusServer || !wlanName} />
                        )}
                    </Form.Item>

                    <Form.Item label="Certificate" style={{ marginBottom: 0 }}>
                        <Upload
                            accept=".pem"
                            showUploadList={false}
                            customRequest={this.importCertificate}
                            onChange={this.changeRadiusCertificate}
                        >
                            <Button style={{ width: "100%" }} disabled={!isWlanModalLoaded || !wlanRadiusServer || !wlanName}>
                                <Icon type="upload" /> Import
                            </Button>
                        </Upload>
                    </Form.Item>

                    {modalError ? <div style={{ marginTop: "12px", color: "red", textAlign: "center" }}>{modalError}</div> : null}

                </Modal>

                {/* Port Forwarding */}

                <Modal
                    width="750px"
                    centered
                    closable={false}
                    visible={isPortForwardingModalVisible}
                    onOk={() => this.confirmRule()}
                    onCancel={this.closeRuleModal}
                    okText="Confirm"
                    confirmLoading={!isRuleModalLoaded}
                    destroyOnClose
                    okButtonProps={{ disabled: confirmPortForwardingDisabled }}
                >
                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('portForwardingState', {
                            onChange: this.changePortForwardingState,
                            initialValue: portForwardingState
                        })(
                            <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item 
                        label="Description" 
                        style={{ marginBottom: 0 }}
                        help={isRuleModalEdited && "Changing the description will result in a new entry!"}
                    >
                        {getFieldDecorator('portForwardingName', {
                            onChange: this.changePortForwardingName,
                            rules: [{ required: true, message: 'Please enter a description!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }],
                            initialValue: portForwardingName
                        })(
                            <Input placeholder="Please enter a description" disabled={!isRuleModalLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Source Port" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('portForwardingPublicPort', {
                            onChange: this.changePortForwardingPublicPort,
                            rules: [{ required: true, message: 'Please enter a port!' }],
                            initialValue: portForwardingPublicPort
                        })(
                            <InputNumber placeholder="80" style={{ width: "100%" }} min={1} max={65535} disabled={!isRuleModalLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Destination Address" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('portForwardingAddress', {
                            onChange: this.changePortForwardingAddress,
                            rules: [{ required: true, message: 'Please enter a name or address!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }],
                            initialValue: portForwardingAddress
                        })(
                            <Input placeholder="192.168.20.20" disabled={!isRuleModalLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Destination Port" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('portForwardingPrivatePort', {
                            onChange: this.changePortForwardingPrivatePort,
                            rules: [{ required: true, message: 'Please enter a port!' }],
                            initialValue: portForwardingPrivatePort
                        })(
                            <InputNumber placeholder="80" style={{ width: "100%" }} min={1} max={65535} disabled={!isRuleModalLoaded} />
                        )}
                    </Form.Item>

                    {modalError ? <div style={{ marginTop: "12px", color: "red", textAlign: "center" }}>{modalError}</div> : null}

                </Modal>

                {/* Outbound Rules */}

                <Modal
                    width="750px"
                    centered
                    closable={false}
                    visible={isOutboundModalVisible}
                    onOk={() => this.confirmRule(true)}
                    onCancel={this.closeRuleModal}
                    okText="Confirm"
                    confirmLoading={!isRuleModalLoaded}
                    destroyOnClose
                    okButtonProps={{ disabled: confirmOutboundRuleDisabled }}
                >
                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('portForwardingState', {
                            onChange: this.changePortForwardingState,
                            initialValue: portForwardingState
                        })(
                            <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                <Radio.Button value={true} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                <Radio.Button value={false} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item 
                        label="Description" 
                        style={{ marginBottom: 0 }}
                        help={isRuleModalEdited && "Changing the description will result in a new entry!"}
                    >
                        {getFieldDecorator('portForwardingName', {
                            onChange: this.changePortForwardingName,
                            rules: [{ required: true, message: 'Please enter a description!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }],
                            initialValue: portForwardingName
                        })(
                            <Input placeholder="Please enter a description" disabled={!isRuleModalLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Source Address" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('outboundRuleAddress', {
                            onChange: this.changeOutboundRuleAddress,
                            rules: [{ required: true, message: 'Please enter a name or address!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }],
                            initialValue: outboundRuleAddress
                        })(
                            <Input placeholder="192.168.20.20" disabled={!isRuleModalLoaded} />
                        )}
                    </Form.Item>

                    {modalError ? <div style={{ marginTop: "12px", color: "red", textAlign: "center" }}>{modalError}</div> : null}

                </Modal>

                {/* HeiTel */}
                            
                <Modal
                    width="750px"
                    centered
                    closable={false}
                    visible={isHeitelModalVisible}
                    onOk={this.confirmHeitelServer}
                    onCancel={this.cancelModal}
                    okText="Confirm"
                    confirmLoading={!isHeitelModalLoaded}
                    destroyOnClose
                    okButtonProps={{ disabled: confirmHeitelServerDisabled }}
                >
                    <Form.Item label="Server" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('heitelServerName', {
                            onChange: this.changeHeitelServerName,
                            rules: [{ required: true, message: 'Please enter a server name or address!' }, { pattern: REGEX.NAME, message: REGEX.NAME_MSG }]
                        })(
                            <Input placeholder="Please enter a server name or address" disabled={!isHeitelModalLoaded} />
                        )}
                    </Form.Item>

                    {modalError ? <div style={{ marginTop: "12px", color: "red", textAlign: "center" }}>{modalError}</div> : null}

                </Modal>

                <ConfirmButton
                    loading={!isLoaded}
                    onConfirm={this.confirm}
                />

            </Form>
        )
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(ConnectivityForm));